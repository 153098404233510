import { Box, Container, Divider, Stack, Heading } from '@chakra-ui/react'

export const SectionHeader = (props) => (
  <Box as="section" bg="bg.surface" pt={{ base: '4', md: '8' }} pb={{ base: '3', md: '6' }}>
    
      <Stack spacing="5">
        <Heading size={"lg"} fontWeight="medium">
        {props.title}
        </Heading>
        <Divider />
      </Stack>
   
  </Box>)