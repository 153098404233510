import React from 'react';
import image from '../images/bg_promo.jpeg';
import '../css/styles.css';
import { SimpleGrid } from '@chakra-ui/react';
import { MdLaunch } from 'react-icons/md';

function DailyBonuses() {
  return (
    <div
      className="video-section padding-top padding-bottom bg-attachment"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="container">
        <div className="section-header">
          <h2>Cele mai bune promoții crypto</h2>
          <p>
            Te ținem la curent cu cele mai bune oferte și bonusuri oferite de
            cazinourile și casele de pariuri online cu criptomonede.
          </p>
        </div>
        <div className="section-wrapper">
          <div class="row g-4">
            <div className="col-12">
              <div className="video-top">
                <div class="row g-4 justify-content-center">
                  <div className="col-lg-6 col-12">
                    <div className="video-item">
                      <div className="video-inner position-relative">
                        <div className="video-thumb position-relative video-overlay">
                          <img
                            src="assets/images/video/01.jpg"
                            alt="video-thumb"
                            className="w-100"
                          />
                          <div className="video-icon">
                            <a
                              href="https://www.youtube.com/embed/g5eQgEuiFC8"
                              data-rel="lightcase"
                            >
                              <i className="icofont-play-alt-1"></i>
                              <span className="pluse"></span>
                            </a>
                          </div>
                        </div>
                        <div className="video-content abs-video-content">
                          <a
                            href="https://www.youtube.com/embed/g5eQgEuiFC8"
                            data-rel="lightcase"
                          >
                            vezi detalii <i className="icofont-play-alt-1"></i>
                          </a>
                          <h3>2022 LVP SuperLiga Orange Summer</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="video-item">
                      <div className="video-inner position-relative">
                        <div className="video-thumb position-relative video-overlay">
                          <img
                            src="assets/images/video/02.jpg"
                            alt="video-thumb"
                            className="w-100"
                          />
                          <div className="video-icon">
                            <a
                              href="https://www.youtube.com/embed/g5eQgEuiFC8"
                              data-rel="lightcase"
                            >
                              <i className="icofont-play-alt-1"></i>
                              <span className="pluse"></span>
                            </a>
                          </div>
                        </div>
                        <div className="video-content abs-video-content">
                          <a
                            href="https://www.youtube.com/embed/g5eQgEuiFC8"
                            data-rel="lightcase"
                          >
                            vezi detalii <i className="icofont-play-alt-1"></i>
                          </a>
                          <h3>2022 LVP SuperLiga Orange Summer</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                /
              </div>
            </div>
            <div className="col-12">
              <div className="video-bottom">
                <div class="row g-4 justify-content-center">
                  <div className="col-xl-4 col-md-6 col-12">
                    <div className="video-item">
                      <div className="video-inner position-relative">
                        <div className="video-thumb position-relative video-overlay">
                          <img
                            src="assets/images/video/03.jpg"
                            alt="video-thumb"
                            className="w-100"
                          />
                          <div className="video-icon">
                            <a
                              href="https://www.youtube.com/embed/g5eQgEuiFC8"
                              data-rel="lightcase"
                            >
                              <i className="icofont-play-alt-1"></i>
                              <span className="pluse"></span>
                            </a>
                          </div>
                        </div>
                        <div className="video-content abs-video-content">
                          <a
                            href="https://www.youtube.com/embed/g5eQgEuiFC8"
                            data-rel="lightcase"
                          >
                            vezi detalii <i className="icofont-play-alt-1"></i>
                          </a>
                          <h4>IEM Season XVI – Summer</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6 col-12">
                    <div className="video-item">
                      <div className="video-inner position-relative">
                        <div className="video-thumb position-relative video-overlay">
                          <img
                            src="assets/images/video/04.jpg"
                            alt="video-thumb"
                            className="w-100"
                          />
                          <div className="video-icon">
                            <a
                              href="https://www.youtube.com/embed/g5eQgEuiFC8"
                              data-rel="lightcase"
                            >
                              <i className="icofont-play-alt-1"></i>
                              <span className="pluse"></span>
                            </a>
                          </div>
                        </div>
                        <div className="video-content abs-video-content">
                          <a
                            href="https://www.youtube.com/embed/g5eQgEuiFC8"
                            data-rel="lightcase"
                          >
                            vezi detalii <i className="icofont-play-alt-1"></i>
                          </a>
                          <h4>IEM Season XVI – Summer</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-md-6 col-12">
                    <div className="video-item">
                      <div className="video-inner position-relative">
                        <div className="video-thumb position-relative video-overlay">
                          <img
                            src="assets/images/video/05.jpg"
                            alt="video-thumb"
                            className="w-100"
                          />
                          <div className="video-icon">
                            <a
                              href="https://www.youtube.com/embed/g5eQgEuiFC8"
                              data-rel="lightcase"
                            >
                              <i className="icofont-play-alt-1"></i>

                              <span className="pluse"></span>
                            </a>
                          </div>
                        </div>
                        <div className="video-content abs-video-content">
                          <a
                            href="https://www.youtube.com/embed/g5eQgEuiFC8"
                            data-rel="lightcase"
                          >
                            vezi detalii <i className="icofont-play-alt-1"></i>
                          </a>
                          <h4>IEM Season XVI – Summer</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DailyBonuses;
