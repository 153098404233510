import { Box, Container, Divider, HStack, Text } from '@chakra-ui/react'

export const Divide = (props) => (
  <Box bg="bg.surface">
    <Container
      py={{
        base: '4',
        md: '8',
      }}
    >
      <HStack>
        <Divider />
        <Text textStyle="lg" fontWeight="medium" whiteSpace="nowrap">
          {props.title}
        </Text>
        <Divider />
      </HStack>
    </Container>
  </Box>
)