import React from 'react';
import '../css/styles.css';
import { SimpleGrid } from '@chakra-ui/react';

function CasinoRank() {
  return (
    <section
      style={{ backgroundColor: '#080e37' }}
      className="collection-section padding-top padding-bottom"
    >
      <div className="container">
        <div className="section-header">
          <h2>Ce nu trebuie să ratezi</h2>
          <p>
            Descoperă cele mai recente tendințe și oportunități pentru a-ți
            îmbunătăți experiența de gaming și pentru a-ți maximiza câștigurile.
            {/* Rămâi la curent cu tendințele emergente, noile promoții și lansări
            de jocuri, actualizările legislative și cele mai recente tehnologii
            din domeniu. */}
          </p>
        </div>
        <div className="section-wrapper game game-style2">
          <div class="row g-4 justify-content-center">
            <div className="col-lg-6 col-12">
              <div className="game__item item-layer">
                <div className="game__inner text-center">
                  <div className="game__thumb">
                    <img
                      src={require('../images/game/04.png')}
                      alt="game-img"
                    />
                  </div>
                  <div className="game__content">
                    <h4>
                      <a href="#">top cazino românia</a>{' '}
                    </h4>
                    <p>
                      Holisticly underwhe fully researched deliverables for
                      revoluonary sourcess skills and technically sound
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="game__item item-layer">
                <div className="game__inner text-center">
                  <div className="game__thumb">
                    <img
                      src={require('../images/game/06.png')}
                      alt="game-img"
                    />
                  </div>
                  <div className="game__content">
                    <h4>
                      <a href="team-single.html">Top Promoții</a>{' '}
                    </h4>
                    <p>
                      Holisticly underwhe fully researched deliverables for
                      revoluonary sourcess skills and technically sound
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="game__item item-layer">
                <div className="game__inner text-center">
                  <div className="game__thumb">
                    <img
                      src={require('../images/game/07.png')}
                      alt="game-img"
                    />
                  </div>
                  <div className="game__content">
                    <h4>
                      <a href="team-single.html">Bonus fară depunere</a>{' '}
                    </h4>
                    <p>
                      Holisticly underwhe fully researched deliverables for
                      revoluonary sourcess skills and technically sound
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="game__item item-layer">
                <div className="game__inner text-center">
                  <div className="game__thumb">
                    <img
                      src={require('../images/game/05.png')}
                      alt="game-img"
                    />
                  </div>
                  <div className="game__content">
                    <h4>
                      <a href="team-single.html">Rotiri gratuite</a>{' '}
                    </h4>
                    <p>
                      Holisticly underwhe fully researched deliverables for
                      revoluonary sourcess skills and technically sound
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CasinoRank;
