const smSize = 'sm';
const regularBoxSize = 5;

export const articleTwoData = {
  title: 'Cum să alegi un cazinou crypto',
  subtitle: `Alegerea unui cazinou crypto poate părea o sarcină dificilă dat
  fiind numărul mare de opțiuni disponibile astăzi. Tocmai de
  aceea noi iti vom prezenta câteva aspecte de luat în considerare
  atunci când alegi un cazinou crypto.`,
  headingImage:
    'https://www.analyticsinsight.net/wp-content/uploads/2023/04/Best-Crypto-Casino-No-Deposit-Bonus-Codes-For-March-2023.jpg',
  content: [
    {
      heading: 'Cum să alegi un cazinou crypto',
      content: `Alegerea unui cazinou crypto poate părea o sarcină dificilă dat fiind
      numărul mare de opțiuni disponibile astăzi. Iată câteva aspecte de
      luat în considerare atunci când alegi un cazinou crypto:`,
      size: 'xl',
      boxSize: 8,
    },
    {
      heading: 'Securitatea și reputația',
      content: `Primul aspect de care ar trebui să te preocupi atunci când alegi un
      cazinou crypto este securitatea. Cazinourile care îți protejează
      informațiile personale și fondurile sunt cele mai potrivite. Caută
      cazinouri care folosesc criptare SSL și tehnologie blockchain pentru a
      asigura securitatea tranzacțiilor.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: '',
      content: `Reputația cazinoului este un alt factor important. Cercetează pe
      internet și citește recenzii de la alți jucători pentru a te asigura
      că cazinoul este de încredere.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: 'Varietatea jocurilor',
      content: `Un cazinou bun ar trebui să ofere o gamă largă de jocuri. De la
      sloturi până la poker, blackjack și ruletă, opțiunile de joc ar trebui
      să fie variate pentru a satisface diferitele gusturi și preferințe ale
      jucătorilor.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: 'Monedele acceptate',
      content: `Cazinourile crypto oferă mai multe avantaje semnificative. Deși majoritatea cazinourilor crypto acceptă Bitcoin, există multe alte
      criptomonede pe care le poți folosi pentru a paria. Asigură-te că
      cazinoul pe care îl alegi acceptă criptomoneda pe care dorești să o
      folosești. Unele cazinouri crypto acceptă și stablecoins precum USDT
      (Tether), USDC (USD Coin) și BUSD (Binance USD), care sunt mai puțin
      volatile decât alte criptomonede.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: 'Bonusuri și promoții',
      content: `Majoritatea cazinourilor crypto oferă bonusuri și promoții pentru a
      atrage noi jucători și a-i reține pe cei existenți. Verifică ce tipuri
      de bonusuri sunt oferite și citeste cu atenție termenii și condițiile
      pentru a te asigura că sunt echitabile.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
  ],
};
