const smSize = 'sm';
const regularBoxSize = 5;

export const articleOneData = {
  title: 'Introducere în cazinourile crypto',
  subtitle: `Cazinourile crypto reprezintă un concept relativ nou în
  industria jocurilor de noroc, oferind jucătorilor ocazia de a
  paria și de a câștiga bani folosind criptomonede.`,
  headingImage: 'https://alevla.com/media/cryptobetcasino/blogBanners/1.png',
  content: [
    {
      heading: 'Introducere în cazinourile crypto',
      content: `Cazinourile crypto reprezintă un concept relativ nou în industria jocurilor de noroc, oferind jucătorilor ocazia de a paria și de a câștiga bani folosind criptomonede. Această evoluție a venit ca o extensie naturală a creșterii și popularității criptomonedelor în general`,
      size: 'xl',
      boxSize: 8,
    },
    {
      heading: 'Ce sunt cazinourile crypto?',
      content: `Cazinourile crypto sunt platforme online de jocuri de noroc care
      acceptă una sau mai multe criptomonede ca metode de plată. Acestea
      operează similar cu cazinourile online tradiționale, cu diferența că
      jucătorii pot depune și retrage fonduri în criptomonede. Unele
      cazinouri crypto acceptă doar Bitcoin, în timp ce altele acceptă o
      varietate de criptomonede, inclusiv Ethereum, Litecoin, Bitcoin Cash
      și altele.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: 'Cum funcționează cazinourile crypto?',
      content: `Funcționarea unui cazinou crypto este similară cu cea a unui cazinou
      online tradițional. Jucătorii se înregistrează, fac un depozit, aleg
      un joc și încep să parieze. Diferența principală este că, în loc să
      folosească valute tradiționale pentru a face un depozit, jucătorii
      folosesc criptomonede.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: 'Avantajele cazinourilor crypto',
      content: `Cazinourile crypto oferă mai multe avantaje semnificative în
      comparație cu cazinourile online tradiționale. Primul este anonimatul.
      Criptomonedele permit tranzacții anonime, ceea ce înseamnă că
      jucătorii pot depune și retrage bani fără a-și dezvălui identitatea.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: '',
      content: `Un alt avantaj este securitatea. Tranzacțiile cu criptomonede sunt
      securizate prin criptografie, ceea ce înseamnă că este aproape
      imposibil ca acestea să fie compromise. De asemenea, multe cazinouri
      crypto operează pe tehnologia blockchain, care este transparentă și
      imposibil de falsificat.`,
      size: smSize,
      boxSize: 0,
    },
    {
      heading: '',
      content: `De asemenea, criptomoneda permite tranzacții rapide. În timp ce
      retragerile de la cazinourile tradiționale pot dura câteva zile,
      retragerile de criptomonede sunt adesea instantanee sau durează câteva
      ore.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: 'Dezavantajele cazinourilor crypto',
      content: ` Ca orice altceva, cazinourile crypto au și dezavantaje. Una dintre
      preocupările jucătorilor poate fi volatilitatea criptomonedelor.
      Valoarea Bitcoin și a altor criptomonede poate fluctua semnificativ de
      la o zi la alta, ceea ce înseamnă că valoarea câștigurilor unui
      jucător poate varia în mod semnificativ. Cu toate acestea, mulți
      cazinouri și site-uri de pariuri crypto oferă opțiunea de a juca în
      stablecoins cum ar fi USDT (Tether), USDC (USD Coin) și BUSD (Binance
      USD), care sunt ancorate la dolarul american și nu sunt supuse
      aceluiași nivel de volatilitate ca alte criptomonede.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: '',
      content: `Un alt dezavantaj este lipsa de reglementare. Deși acest lucru poate
      oferi mai multă libertate, poate, de asemenea, să facă mai dificilă
      rezolvarea disputelor sau a problemelor cu cazinoul.`,
      size: smSize,
      boxSize: 0,
    },
  ],
};
