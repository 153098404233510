import React from 'react';
import '../css/styles.css';
import image from '../images/bg_match.jpeg';
import { Center, SimpleGrid, Text } from '@chakra-ui/react';
import { posts } from '../components/BlogPost/data';

function BlogArticles() {
  return (
    <div
      className="game-section padding-top padding-bottom overflow-hidden"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="container">
        <div className="section-header">
          <h2>Top Resurse Educaționale</h2>
        </div>
        <div class="row g-4 grid">
          {posts.slice(0, 4).map(post => (
            <div className="col-lg-6 col-12 cat-1">
              <div className="game__item item-layer">
                <div className="game__inner text-center p-0">
                  <div className="game__thumb mb-0">
                    <img
                      src={post.image}
                      alt="game-img"
                      className="rounded-3 w-100"
                    />
                  </div>

                  <div className="game__overlay">
                    <div className="game__overlay-left">
                      <h4>Categorie: {post.category}</h4>
                      <Text color="white">{post.title}</Text>
                    </div>
                    <div className="game__overlay-right">
                      <a href={`/${post.URL}`} className="default-button">
                        <span>
                          Citeste acum <i className="icofont-circled-right"></i>
                        </span>{' '}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <Center mt={5}>
          <a href="/blog" className="default-button">
            <span>
              Vezi toate articolele <i className="icofont-circled-right"></i>
            </span>
          </a>
        </Center>
      </div>
    </div>
  );
}

export default BlogArticles;
