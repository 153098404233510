const smSize = 'sm';
const regularBoxSize = 5;

export const articleSixData = {
  title: 'Ce sunt bonusurile de bun venit în cazinourile crypto?  ',
  subtitle: `Bonusurile de bun venit sunt un tip de promoție pe care cazinourile le folosesc pentru a atrage noi jucători. În cazinourile crypto, aceste bonusuri pot lua diferite forme`,
  headingImage:
    'https://www.analyticsinsight.net/wp-content/uploads/2023/04/Best-Crypto-Casino-No-Deposit-Bonus-Codes-For-March-2023.jpg',
  content: [
    {
      heading: 'Bonus la primul depozit',
      content: `Multe cazinouri oferă un bonus la primul depozit, în care cazinoul va potrivi o anumită procent din primul tău depozit.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: 'Rotiri gratuite',
      content: `Unele cazinouri oferă rotiri gratuite ca parte a bonusului de bun venit. Aceste rotiri gratuite pot fi folosite la anumite jocuri de sloturi.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: 'Bonus fără depozit',
      content: `Unele cazinouri oferă un bonus fără depozit, care îți permite să începi să joci fără a trebui să depui propriile criptomonede.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
  ],
};
