import React from 'react';
import {
  Box,
  Button,
  Container,
  Heading,
  Stack,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  SimpleGrid,
  HStack,
} from '@chakra-ui/react';
import { MdOutlineLaunch } from 'react-icons/md';
import { AiFillStar } from 'react-icons/ai';
import { HiBadgeCheck } from 'react-icons/hi';

function PageHeader(props) {
  return (
    <Box
      as="section"
      bg="gray.100"
      py={{
        base: '8',
        md: '16',
      }}
    >
      <Container>
        <SimpleGrid
          spacing={{ sm: 10, lg: 50, xl: 100 }}
          columns={{ md: 1, lg: 2 }}
        >
          <Stack
            spacing={{
              base: '8',
              md: '10',
            }}
          >
            <Stack
              spacing={{
                base: '4',
                md: '6',
              }}
            >
              <Stack spacing="3">
                <Text
                  fontSize={{
                    base: 'sm',
                    md: 'md',
                  }}
                  fontWeight="medium"
                  color="accent"
                >
                  {props.tagline}
                </Text>
                <Heading
                  size={{
                    base: 'md',
                    md: 'lg',
                  }}
                  fontWeight="semibold"
                >
                  {props.title}
                </Heading>
              </Stack>
              <Text
                color="fg.muted"
                fontSize={{
                  base: 'lg',
                  md: 'xl',
                }}
                maxW="3xl"
              >
                {props.description}
              </Text>
            </Stack>
            <Stack
              direction={{
                base: 'column-reverse',
                md: 'row',
              }}
            >
              <Button
                style={{
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                }}
                variant="primary"
                size="xl"
              >
                {props.cta1} <MdOutlineLaunch />
              </Button>
              <Button
                style={{
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                }}
                variant="secondary"
                size="xl"
              >
                {props.cta2}
              </Button>
            </Stack>
          </Stack>

          <Box w="100%" textAlign={'left'}>
            <TableContainer p={3}>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>
                      {' '}
                      <Heading size="xs"> Rating total</Heading>
                    </Th>
                    <Th>
                      <HStack>
                        <AiFillStar color="gold" fontSize={'20px'} />
                        <Heading size="xs">
                          {props.totalRating} / 10
                        </Heading>{' '}
                      </HStack>{' '}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>
                      <Text>Rating cazino</Text>
                    </Td>
                    <Td>{props.ratingCazino} / 10</Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text>Rating pariuri sportive</Text>
                    </Td>
                    <Td>{props.ratingBetting} / 10</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
            <HStack>
              <HiBadgeCheck color="green" display="inline" fontSize={'20px'} />
              <Heading p={3} size={'xs'}>
                Recomandăm utilizarea {props.name}
              </Heading>
            </HStack>
            <Text fontSize={'lg'}>Disponibil în România 🇷🇴</Text>
          </Box>
        </SimpleGrid>
      </Container>
    </Box>
  );
}

export default PageHeader;
