const smSize = 'sm';
const regularBoxSize = 5;

export const articleThreeData = {
  title: 'Înțelegerea pariurilor crypto',
  subtitle: `Pariurile crypto reprezintă o nouă frontieră în industria
  pariurilor sportive și de jocuri de noroc, permițând jucătorilor
  să parieze folosind diferite criptomonede. Să înțelegem mai bine
  cum funcționează acestea.`,
  headingImage: 'https://alevla.com/media/cryptobetcasino/1.png',
  content: [
    {
      heading: 'Înțelegerea pariurilor crypto',
      content: `Pariurile crypto reprezintă o nouă frontieră în industria pariurilor
      sportive și de jocuri de noroc, permițând jucătorilor să parieze
      folosind diferite criptomonede. Să înțelegem mai bine cum funcționează
      acestea.`,
      size: 'xl',
      boxSize: 8,
    },
    {
      heading: 'Ce sunt pariurile crypto?',
      content: `Pariurile crypto se referă la actul de a plasa pariuri pe diferite
      evenimente sportive sau jocuri de noroc folosind criptomonede ca
      mijloc de tranzacționare. Acest tip de pariuri a câștigat popularitate
      datorită creșterii generale a utilizării criptomonedelor.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: 'Cum funcționează cazinourile crypto?',
      content: `Pariurile crypto funcționează în mod similar cu pariurile online
      tradiționale. Jucătorii își deschid un cont, fac un depozit în
      criptomonede, selectează evenimentul pe care doresc să parieze și
      plasează pariul. În cazul în care pariul este câștigător, câștigurile
      sunt plătite în criptomonede și pot fi retrase în portofelul crypto al
      jucătorului.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: 'Avantajele pariurilor crypto',
      content: `Unul dintre avantajele majore ale pariurilor crypto este viteza
      tranzacțiilor. Retragerile sunt adesea mult mai rapide decât cele de
      la casele de pariuri online tradiționale. Un alt avantaj este
      anonimatul; deoarece tranzacțiile cu criptomonede pot fi anonime,
      jucătorii nu trebuie să împărtășească informații personale atunci când
      fac un depozit sau o retragere.`,
      size: smSize,
      boxSize: 0,
    },
    {
      heading: '',
      content: `De asemenea, mulți operatori de pariuri crypto oferă opțiuni pentru a
      paria cu stablecoins, care sunt criptomonede ancorate la valoarea
      dolarului american. Acest lucru poate ajuta la limitarea riscurilor
      asociate cu volatilitatea criptomonedelor.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
  ],
};
