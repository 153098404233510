import React from 'react';
import '../css/styles.css';
import image from '../images/bg.jpeg';

function Hero() {
  return (
    <section className="banner" style={{ backgroundImage: `url(${image})` }}>
      <div className="container">
        <div className="row g-0">
          <div className="col-xl-6 col-lg-7 col-12">
            <div className="banner__content">
              <h3>Recenzii - Educație - Știri</h3>
              <h1>Crypto Bet Casino</h1>
              <p style={{ color: '#ffffff', fontSize: '18px' }}>
                Descoperă lumea dinamică a pariurilor cu criptomonede! Crypto
                Bet Casino este ghidul tău de încredere pentru cele mai bune
                cazinouri cu criptomonede, recenzii obiective și ultimele știri
                din industrie
              </p>
              <a href="login.html" className="default-button">
                <span>
                  join us today <i className="icofont-play-alt-1"></i>
                </span>{' '}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
