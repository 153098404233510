const smSize = 'sm';
const regularBoxSize = 5;

export const articleFourData = {
  title: 'Cum să te înregistrezi și să joci la un cazinou crypto',
  subtitle: `Jocul la un cazinou crypto poate fi o experiență interesantă, dar dacă ești nou în acest domeniu, procesul poate părea puțin complicat. Următorii pași îți vor arăta cum să te înregistrezi și să joci la un cazinou crypto.`,
  headingImage: 'https://alevla.com/media/cryptobetcasino/1.png',
  content: [
    {
      heading: '1. Alege un cazinou crypto',
      content: `Primul pas este să alegi un cazinou crypto de încredere. Asigură-te că acesta acceptă criptomoneda pe care dorești să o folosești și oferă jocurile pe care dorești să le joci.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: '2. Înregistrează-te',
      content: `După ce ai ales un cazinou, va trebui să te înregistrezi. Procesul de înregistrare diferă de la un cazinou la altul, dar, de obicei, va trebui să furnizezi o adresă de e-mail și să creezi o parolă.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: '3. Fă un depozit',
      content: `După ce te-ai înregistrat, va trebui să faci un depozit în contul tău de cazinou. Majoritatea cazinourilor crypto îți vor oferi o adresă de depozit unde poți trimite criptomonedele.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: '4. Începe să joci',
      content: `După ce ai făcut un depozit, ești gata să începi să joci. Navighează prin selecția de jocuri a cazinoului și alege unul care îți place. Asigură-te că înțelegi regulile jocului înainte de a începe să pariezi.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: 'Retrage-ți câștigurile',
      content: `Dacă ai avut noroc și ai câștigat, probabil că vei dori să îți retragi câștigurile. Pentru a face acest lucru, va trebui să accesezi pagina de retragere a cazinoului și să urmezi instrucțiunile.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
  ],
};
