import React from 'react';

import { Box, Button, Container, Heading, Stack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export const CTABox = props => (
  <Container
    py={{
      base: '16',
      md: '24',
    }}
  >
    <Box
      bg="bg.surface"
      boxShadow="md"
      borderRadius="xl"
      px={{
        base: '6',
        lg: '16',
      }}
      py={{
        base: '10',
        lg: '16',
      }}
    >
      <Stack
        spacing={{
          base: '8',
          md: '10',
        }}
      >
        <Stack
          spacing={{
            base: '4',
            md: '5',
          }}
          align="center"
        >
          <Heading
            size={{
              base: 'sm',
              md: 'md',
            }}
          >
            {props.title}
          </Heading>
          <Text color="fg.muted" maxW="2xl" textAlign="center" fontSize="xl">
            {props.description}
          </Text>
        </Stack>
        <Stack
          spacing="3"
          direction={{
            base: 'column',
            sm: 'row',
          }}
          justify="center"
        >
          <Link to={props.link1}>
            <Button variant="secondary" size="xl">
              {props.button1}
            </Button>
          </Link>
          <Link to={props.link2}>
            <Button variant="primary" size="xl">
              {props.button2}
            </Button>
          </Link>
        </Stack>
      </Stack>
    </Box>
  </Container>
);
