const smSize = 'sm';
const regularBoxSize = 5;

export const articleFiveData = {
  title: 'Securitatea în cazinourile crypto',
  subtitle: `Când vine vorba de jocurile de noroc online, securitatea este o preocupare majoră pentru mulți jucători. Aici sunt câteva aspecte pe care ar trebui să le iei în considerare atunci când joci în cazinourile crypto`,
  headingImage: 'https://alevla.com/media/cryptobetcasino/2.png',
  content: [
    {
      heading: 'Criptografia',
      content: `Criptografia este esențială pentru securitatea tranzacțiilor cu criptomonede. Când alegi un cazinou crypto, asigură-te că folosește criptografie puternică pentru a proteja detaliile tranzacțiilor tale.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: 'Tehnologia Blockchain',
      content: `Multe cazinouri crypto folosesc tehnologia blockchain pentru a asigura transparența și securitatea jocurilor. Blockchain-ul este practic imposibil de falsificat, ceea ce înseamnă că poți avea încredere în rezultatele jocurilor.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: 'Autentificarea în doi factori',
      content: `Autentificarea în doi factori (2FA) este o altă caracteristică de securitate pe care ar trebui să o cauți la un cazinou crypto. Aceasta necesită un al doilea pas de verificare atunci când te autentifici în contul tău, ceea ce ajută la protejarea contului tău împotriva accesului neautorizat.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: 'Reglementarea',
      content: `Deși reglementarea cazinourilor crypto poate varia de la o jurisdicție la alta, alegerea unui cazinou care este licențiat și reglementat de o autoritate recunoscută poate aduce un plus de siguranță.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: 'Reputația',
      content: `Reputația unui cazinou poate fi un bun indicator al securității sale. Cercetează și citește recenzii pentru a afla experiența altor jucători.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
    {
      heading: '',
      content: `Prin urmare, înainte de a te alătura oricărui cazinou crypto, este esențial să te asiguri că îți poți proteja fondurile și informațiile personale.`,
      size: smSize,
      boxSize: regularBoxSize,
    },
  ],
};
