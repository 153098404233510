import React from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import { SectionHeader } from '../../components/SectionHeader/SectionHeader';
import {
  Text,
  Heading,
  Container,
  List,
  ListItem,
  ListIcon,
  Flex,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  HStack,
  UnorderedList,
  Table,
  TableContainer,
  Thead,
  Td,
  Tr,
  Th,
  Tbody,
  SimpleGrid,
  Image,
  Grid,
  GridItem,
  Show,
} from '@chakra-ui/react';
import { MdCheck } from 'react-icons/md';
import { cloudbetHighlits, cloudbetStats } from './dataCloudbet';
import { AiFillCloseCircle, AiFillStar } from 'react-icons/ai';
import { Stat } from '../../components/Stat/Stat';
import { Divide } from '../../components/Divider/Divider';
import { HiBadgeCheck } from 'react-icons/hi';

function ReviewCloudbet() {
  return (
    <>
      <PageHeader
        tagline="Bonus de bun venit 100% până la 5BTC"
        title="Recenzie Cloudbet"
        description={`în această recenzie vom împărtăși cu tine ceea ce am descoperit după ce am petrecut nenumărate ore citind despre și interacționând cu crypto cazinoul online Cloudbet. Sunt prezentate mai multe capturi de ecran, toate caracteristicile site-ului, și atât avantajele, cât și dezavantajele pe care le-am identificat.`}
        cta1="Vizitează Cloudbet.com"
        cta2="Vezi celelalte cazinouri recomandate"
        name="Cloudbet"
        totalRating="9.17"
        ratingCazino="9.1"
        ratingBetting="9.25"
        href=""
      />
      <Grid templateColumns="repeat(12, 1fr)" gap={2}>
        <GridItem
          colSpan={{ md: 8, sm: 12, base: 12 }}
          px={{ base: 0, md: 0, lg: 20 }}
        >
          <Container px={16} pb={10} fontSize={'18px'}>
            <SectionHeader title="Prezentare generală " />
            <Box h={10} />
            <Image src="https://cryptobetcasino.ro/images/stake/img_1.png" />
            <Box h={10} />
            <Flex
              wrap={'wrap'}
              justifyContent={'center'}
              margin={'auto'}
              gap={{
                base: '5',
                md: '6',
              }}
            >
              {cloudbetStats.map(({ label, value, icon }) => (
                <Stat key={label} label={label} value={value} icon={icon} />
              ))}
            </Flex>
          </Container>

          <Container px={16} py={10}>
            <SectionHeader title="Recomandări cheie" />
            <Heading size="sm" mb={7}>
              Numeroasele beneficii Cloudbet
            </Heading>
            <List spacing={3}>
              {cloudbetHighlits.pro.map((highlight, index) => (
                <ListItem>
                  <ListIcon as={MdCheck} color="green.500" />
                  <span style={{ fontSize: '24px', fontWeight: 'bold' }}>
                    {highlight.title}
                  </span>
                  <Text mb={5}>{highlight.description}</Text>
                </ListItem>
              ))}
            </List>
            <br />
            <Heading mb={7} size="sm">
              Dezavantajele Cloudbet
            </Heading>
            <List spacing={3}>
              {cloudbetHighlits.con.map((highlight, index) => (
                <ListItem>
                  <ListIcon as={AiFillCloseCircle} color="red.500" />
                  <span style={{ fontSize: '24px', fontWeight: 'bold' }}>
                    {highlight.title}
                  </span>
                  <Text mb={5}>{highlight.description}</Text>
                </ListItem>
              ))}
            </List>
          </Container>

          <Container px={16} pb={10} fontSize={'18px'}>
            <SectionHeader title="Este Cloudbet.com legitim?" />
            <HStack>
              <AiFillStar color="gold" fontSize={'40px'} />
              <Heading size="md">7.5 / 10</Heading>{' '}
            </HStack>{' '}
            <Box h={10} />
            <Text as="b">
              Cloudbet este un site de încredere de pariuri sportive și de
              jocuri de cazinou. Oferă mai multe caracteristici utile, însă
              lipsesc anumite lucruri pe care le vom detalia mai jos.
            </Text>
            <Box h={10} />
            <Heading as="h6" size="sm" mb={5}>
              Licență(e)
            </Heading>
            <Text>
              Licența pentru Cloudbet este în Curacao, ceea ce este destul de
              standard pentru acest sector. Majoritatea site-urilor au licențe
              locale sau din Costa Rica.
            </Text>
            <Box h={10} />
            <Heading as="h6" size="sm" mb={5}>
              Opțiuni de securitate
            </Heading>
            <Text>
              <Text as="b">Un site web "100% sigur", conform Cloudbet.</Text>
              <br />
              <br />
              Site-ul are un certificat SSL, iar jocurile sunt testate prin RNG
              și se poate dovedi că sunt corecte. Pot fi configurate atât
              verificarea prin SMS, cât și autentificarea cu doi factori.
              <br />
              <br />
              În plus, îți permit să joci anonim dacă folosești criptomonede.
              Deși tot ce ai nevoie pentru a te înscrie este un e-mail, își
              rezervă posibilitatea de a solicita KYC atunci când faci retrageri
              în bani fiat.
            </Text>
            <Box h={10} />
            <Heading as="h6" size="sm" mb={5}>
              Reputația proprietarilor
            </Heading>
            <Text>
              Halcyon Super Holdings BV este proprietarul Cloudbet, însă, în
              ciuda eforturilor noastre, nu am putut descoperi nicio informație
              despre această afacere.
            </Text>
            <Box h={10} />
            <Heading as="h6" size="sm" mb={5}>
              Dispute raportate
            </Heading>
            <Text>
              În cercetarea noastră, am descoperit mai multe plângeri în plus
              față de reclamațiile comune de fraudă Cloudbet. Serviciul clienți
              slab și plățile lente au fost principalele plângeri pe AskGamblers
              și TrustPilot.
              <br />
              <br />
              Acestea fiind spuse, majoritatea problemelor tind să primească un
              răspuns rapid, iar Cloudbet menține o pagină pe Bitcointalk, unde
              pare să răspundă prompt la comentarii și nemulțumiri. Totodată,
              dispune atât de o pagină Twitter cât și de un canal Discord.
              <br />
              <br />
              Majoritatea problemelor par să fi fost rezolvate în mod
              satisfăcător. Așadar, este Couldbet o companie legitimă? Da,
              pentru că este clar că încearcă să își deservească bine clienții
              și să gestioneze problemele.
            </Text>
          </Container>

          <Container px={16} pb={10} fontSize={'18px'}>
            <SectionHeader title="Caracteristicile Cazinoului" />
            <HStack>
              <AiFillStar color="gold" fontSize={'40px'} />
              <Heading size="md">10 / 10</Heading>{' '}
            </HStack>{' '}
            <Box h={10} />
            <Text>
              Printre numeroșii furnizori majori de jocuri reprezentați de
              selecția extinsă de jocuri de cazino de la Cloudbet se numără:
              <Box h={5} />
              <UnorderedList>
                <ListItem>Spinomenal Play'n Go</ListItem>
                <ListItem>BetSoft</ListItem>
                <ListItem>Netent</ListItem>
                <ListItem>Microgaming</ListItem>
                <ListItem>Evolution Gaming</ListItem>
                <ListItem>Quickspin</ListItem>
                <ListItem>Betsoft</ListItem>
                <ListItem>Jocuri de noroc de Tom Horn</ListItem>
                <ListItem>Gamevy Playson Evolution Gaming</ListItem>
              </UnorderedList>
              <Box h={5} />
            </Text>
            <Image
              my={10}
              mx={'auto'}
              width={{ base: '100%', md: '75%' }}
              src="https://cryptobetcasino.ro/images/stake/img_8.png"
            />
            <Box h={5} />
            <Text>
              Cazinoul pune la dispoziție jocuri de masă, baccarat, blackjack,
              ruletă, jocuri arcade, sloturi cu jackpot, jocuri virtuale și
              blackjack.
              <br />
              <br />
              Numeroase mese live și mii de sloturi, inclusiv sloturi cu
              jackpot, se numără printre facilitățile oferite de acest cazinou.
              <br />
              <br />
              Mai jos sunt enumerate câteva dintre jocurile disponibile:
            </Text>
            <Box h={5} />
            <Heading as="h6" size="sm" mb={5}>
              Sloturi
            </Heading>{' '}
            <Box h={5} />
            <UnorderedList>
              <ListItem>Court of Hearts</ListItem>
              <ListItem>Penny Fruits Xtreme</ListItem>
              <ListItem>Fire Joker</ListItem>
              <ListItem>Wolf Sierra</ListItem>
            </UnorderedList>
            <Box h={5} />
            <Heading as="h6" size="sm" mb={5}>
              Sloturi cu Jackpot
            </Heading>{' '}
            <Box h={5} />
            <UnorderedList>
              <ListItem>The Slotfather</ListItem>
              <ListItem>Golden Goal</ListItem>
              <ListItem>Chicago Gangsters</ListItem>
              <ListItem>It Came From Venus Plus</ListItem>
              <ListItem>Greedy Goblins</ListItem>
            </UnorderedList>
            <Box h={5} />
            <Heading as="h6" size="sm" mb={5}>
              Jocuri Live
            </Heading>{' '}
            <Box h={5} />
            <UnorderedList>
              <ListItem>Lightning Roulette</ListItem>
              <ListItem>Power Blackjack</ListItem>
              <ListItem>Infinite Blackjack</ListItem>
              <ListItem>Lightning Dice</ListItem>
            </UnorderedList>
            <Box h={5} />
            <Heading as="h6" size="sm" mb={5}>
              Jocuri de masa
            </Heading>{' '}
            <Box h={5} />
            <UnorderedList>
              <ListItem>First Person Dragon Tiger</ListItem>
              <ListItem>3 Hand Casino Hold’em</ListItem>
              <ListItem>Money Wheel</ListItem>
              <ListItem>First Person Craps</ListItem>
              <ListItem>Triple Edge Poker</ListItem>
            </UnorderedList>
            <Box h={5} />
            <Text>
              {' '}
              Opțiunile suplimentare includ jocuri first-hand, buy-in-uri de
              speciale și multe altele. În funcție de locația în care te afli,
              vor fi disponibile diferite jocuri.
            </Text>
            <Box h={5} />
            <Heading as="h6" size="sm" mb={5}>
              Cazinoul actual
            </Heading>{' '}
            <Text>
              {' '}
              La Cloudbet, vei găsi o selecție fantastică de jocuri de cazino
              live de la Evolution Gaming, Netent, Vivo si altele. Cantitatea de
              jocuri din această categorie ne-a surprins cu adevărat. Nu am
              reușit să localizam niciun joc exclusivist, ceea ce este
              regretabil. Bineînțeles, acest lucru nu este o problemă, iar
              jocurile arcade distractive care au fost adăugate compensează cu
              prisosință.
            </Text>
          </Container>

          <Container px={16} pb={10} fontSize={'18px'}>
            <SectionHeader title="Pariurile sportive " />
            <HStack>
              <AiFillStar color="gold" fontSize={'40px'} />
              <Heading size="md">9 / 10</Heading>{' '}
            </HStack>{' '}
            <Box h={10} />
            <Text as={'b'}>
              O gamă largă de piețe live și un repertoriu excepțional de
              evenimente sportive sunt disponibile la Cloudbet.
            </Text>
            <Box h={5} />
            <Text>
              Joacă pe toate sporturile populare. Există numeroase sporturi
              accesibile, inclusiv ciclism, golf, sporturi cu motor, tenis,
              hochei, baschet, fotbal american, volei pe plajă, box, MMA și
              multe altele. Sunt disponibile și opțiuni speciale, cum ar fi
              politica și divertismentul.
              <br />
              <br />
              Chiar dacă este un mic detaliu, ne place foarte mult modul în care
              este alcătuit site-ul de pariuri sportive în ansamblu. Este foarte
              bine organizat și direct. Ne-am dori să putem urmări transmisiuni
              live, dar, din păcate, sunt disponibile doar eSports.
              <br />
              <br />
              Apreciem faptul că oferă pariuri personalizate pe lângă selecțiile
              standard. Ca urmare, fiecare meci devine mult mai plăcut de
              urmărit și de pariat!
            </Text>
            <Box h={5} />
            <Heading as="h6" size="sm" mb={5}>
              Pariuri pe sporturi virtuale
            </Heading>{' '}
            <Text>
              {' '}
              Baschetul și fotbalul sunt doar două dintre sporturile virtuale
              realiste HD pe care Cloudbet le oferă! <br />
              <br />
              Bineînțeles, nu lipsește nici categoria de eSports. Pariază pe:{' '}
              <br /> <br />
              Call of Duty <br />
              Counter-Strike <br />
              Dota 2 <br />
              King of Glory <br />
              League of Legends <br />
              Rainbow Six <br />
              StarCraft <br />
              Valorant <br />
              Warcraft <br />
              Discord <br />
              <br />
              Cloudbet are limite de pariuri sportive de până la 10 BTC cu
              pariuri înainte de joc și live și mai multe piețe speciale pentru
              fiecare eveniment!
              <br />
              <br />
              Dacă ești un fan de pariuri sportive, cu siguranță te vei bucura
              de opțiunile de pariuri sportive Cloudbet.
            </Text>
          </Container>

          <Container px={16} pb={10} fontSize={'18px'}>
            <SectionHeader title="Poker" />
            <Box h={10} />
            <Text>
              Din păcate, Cloudbet oferă doar video poker ca formă de poker. Dar
              dacă ceva se modifică, ne vom asigura că vom actualiza această
              pagină!
            </Text>
            <Box h={10} />
          </Container>

          <Container px={16} pb={10} fontSize={'18px'}>
            <SectionHeader title="Experiența jocurilor de noroc cu miză" />
            <HStack>
              <AiFillStar color="gold" fontSize={'40px'} />
              <Heading size="md">10 / 10</Heading>{' '}
            </HStack>{' '}
            <Box h={10} />
            <Text as="b">
              O excelentă experiență de joc este oferită de Cloudbet, iar unele
              dintre caracteristici ne-au plăcut foarte mult. Să examinăm mai în
              detaliu ce oferă site-ul.
            </Text>
            <Box h={10} />
            <Heading as="h6" size="sm" mb={5}>
              Funcționalitatea și caracteristicile platformei
            </Heading>
            <Text>
              Platforma de la Cloudbet este elegantă și simplu de utilizat.
              Posibilitatea de a modifica tema de fundal este o mică
              caracteristică îngrijită pe care am considerat-o excelentă.
              <br />
              <br />
              Acesta este un instrument fantastic pentru reducerea oboselii
              ochilor. Îl poți seta pe auto și va comuta automat între
              întuneric, clasic și luminos.
              <br />
              <br />
              În afară de această mică diversiune interesantă, opțiunile de joc
              sunt simplu de identificat și de urmărit.
              <br />
              <br />
              Găsirea informațiilor de contact, a informațiilor despre depozite
              și retrageri și a altor informații, s-a dovedit a fi o provocare.
              O pagină de întrebări frecvente ar fi benefică.
              <br />
              <br />
              În plus, site-ul Cloudbet pentru mobil este realizat pentru jocuri
              simple pe telefon sau tabletă!
              <br />
              <br />O caracteristică suplimentară fantastică este blogul
              actualizat frecvent. Aici pot fi găsite informații despre
              promoții, metode de pariere și chiar cunoștințe generale despre
              bitcoin.
            </Text>
            <Box h={10} />
            <Heading as="h6" size="sm" mb={5}>
              Design și aspect
            </Heading>
            <Text>
              Începem cu pagina principală. Simplitatea paginii de start a
              Cloudbet este atrăgătoare. Arată bine și este informativă fără a
              deveni sufocantă.
              <br />
              <br />
              Designul culorilor pentru versiunile desktop și mobile ale
              site-urilor web este extrem de drăguț. Asa cum am spus deja, poți
              modifica tema de fundal pentru a face aspectul mai plăcut din
              punct de vedere estetic pentru tine.
              <br />
              <br />
            </Text>
            <Box h={10} />
            <Heading as="h6" size="sm" mb={5}>
              Procesul de înregistrare
            </Heading>
            <Text>
              Este destul de ușor să te înregistrezi. Folosește adresa ta de
              e-mail sau creează un cont Github, Facebook sau Google.
              <br />
              <br />
              Selectează o limbă, bifează căsuța care indică faptul că ai vârsta
              legală pentru jocuri de noroc și gata! Ești admis!
              <br />
              <br />
              În urma înregistrării, poți selecta numele de utilizator, ziua de
              naștere, formatul pentru cotele sportive și poți depune bani în
              contul tău folosind un card sau un portofel de criptomonede.
              <br />
              <br />
              Când ajungi la secțiunea "adaugă fonduri", poți fie să folosești
              MoonPay, fie să selectezi moneda și să cumperi criptomonede direct
              cu cardul tău.
            </Text>
            <Box h={10} />
            <Heading as="h6" size="sm" mb={5}>
              Program Responsible Gambling
            </Heading>
            <Text>
              Un lucru bun pe care trebuie să îl menționăm aici este că,
              Cloudbet oferă ajutor celor pentru care jocurile de noroc se
              transformă într-o adevărată dependență.
              <br />
              <br />
              Dacă simți că lucrurile îți scapă de sub control cu jocurile de
              noroc Cloudbet, poți profita de instrumentele de joc responsabil
              ale platformei.
              <br />
              <br />
              De exemplu, poți să te auto-excluzi sau să iei o pauză,
              asigurându-te că nu joci cu mai mult decât îți permiți să pierzi,
              sau pur și simplu poți sta departe de site pentru o perioadă.
              <br />
              <br />
              De asemenea, poți cere ajutor prin intermediul linkului Gamble
              Aware din subsolul site-ului Cloudbet.com.
            </Text>
          </Container>

          <Container px={16} pb={10} fontSize={'18px'}>
            <SectionHeader title="Bonus de bun venit" />
            <Box h={10} />
            <Text>
              <b>
                Jucătorii noi de la Cloudbet pot primi un bonus de bun venit cu
                depunere de 100% până la 5 BTC!
              </b>
              <br />
              <br />
              Depune până la:
              <Box h={5} />
              <UnorderedList>
                <ListItem>5 BTC/BCH/ETH/PAXG/DASH/LTC</ListItem>
                <ListItem>100 LINK</ListItem>
                <ListItem>1.000 USDT/USDC/DAI/PAX</ListItem>
                <ListItem>10.000 DOGE</ListItem>
                <ListItem>Triple Edge Poker</ListItem>
              </UnorderedList>{' '}
              <Box h={5} />
              după ce îți creezi contul Cloudbet.
              <br />
              <br />
              Vei primi imediat credit pentru bonusul tău și poți începe să
              joci!
            </Text>
            <Box h={10} />
            <Heading as="h6" size="sm" mb={5}>
              Reguli pentru bonusul de bun venit
            </Heading>
            <Text>
              Pentru această ofertă, este necesară o depunere de doar 0,01 BTC.{' '}
              <br />
              <br />
              Primești bonusul prin acumularea de puncte de loialitate în timp
              ce joci jocuri de cazino și sport.
              <br />
              <br />
              Bonusul tău va fi creditat în aceeași monedă pe care ai folosit-o
              pentru a face primul depozit.
              <br />
              <br />
              În legătură cu bonusul de prim depozit BTC: Pentru fiecare 800 de
              puncte de loialitate pe care le câștigi, veți primi 0,01 BTC. Până
              la un maxim de 5 BTC, aceste creșteri de 0,01 BTC vor fi creditate
              imediat în contul tău.
            </Text>
            <Box h={10} />
            <Heading as="h6" size="sm" mb={5}>
              Puncte de recompensă
            </Heading>
            <Text>
              Iată cum se calculează punctele de loialitate:
              <br />
              <br />
              <b>Jocuri pentru casele de pariuri sportive </b>
              <br />
              Cota x 0,1 x miza convertită = puncte de loialitate
              <br />
              <br />
              <b>Jocuri cazino</b>
              <br />
              Puncte de loialitate = Miza convertită x 0,008 x (100-RTP)
              <br />
              <br />
              Blackjack-ul câștigă cele mai puține puncte, în timp ce sloturile
              câștigă cele mai multe.
            </Text>
          </Container>

          <Container px={16} pb={10} fontSize={'18px'}>
            <SectionHeader title="Promoții" />
            <HStack>
              <AiFillStar color="gold" fontSize={'40px'} />
              <Heading size="md">9 / 10</Heading>{' '}
            </HStack>{' '}
            <Box h={10} />
            <Text as="b">
              În plus față de bonusul de bun venit, Cloudbet derulează și o
              serie de alte promoții. Un bonus la Cloudbet necesită o investiție
              minimă de 0,01 BTC. Ei au un program VIP, dar dacă te califici, un
              manager va lua legătura cu tine. Nu au fost oferite alte detalii.
            </Text>
            <Box h={10} />
            <Heading as="h6" size="sm" mb={5}>
              Pariuri sportive fără marjă
            </Heading>
            <Text>
              Acestea sunt evenimente sportive aleatorii care au cutii galbene
              în jurul lor. NBA, NFL, MLB, MLB, NHL, MMA, fotbal și tenis se
              numără printre sporturi. 1000 de jucători vor fi aleși pentru cote
              cu marjă zero pentru fiecare dintre aceste evenimente.
              <br />
              <br />
              Fiecare jucător are voie să plaseze mai multe pariuri pe un singur
              meci cu marjă zero, atâta timp cât profiturile totale posibile nu
              depășesc 1.000 de dolari în BTC, BCH, ETH, PAXG, USDC sau USDT.
            </Text>
            <Box h={10} />
            <Heading as="h6" size="sm" mb={5}>
              Free Spins Tuesdays
            </Heading>
            <Text>
              Fiecare zi de marți a săptămânii vine cu 20 de Free Spins la jocul
              Twin Spin XXXTreme la o depunere minima de 20$
            </Text>
            <Box h={10} />
            <Heading as="h6" size="sm" mb={5}>
              Mega Match Mondays
            </Heading>
            <Text>
              Iată un sfat pentru îmbunătățirea zilei de luni: În fiecare luni,
              fă o depunere minimă de 1 mBTC și o sumă maximă de 25 mBTC pentru
              a primi un bonus de reîncărcare de 50% până la 25 mBTC.
              <br />
              <br />
              Pentru a te califica, trebuie să fi făcut un depozit în ultimele
              trei zile și trebuie să activezi mai întâi bonusul înainte de a
              face depozitul.
              <br />
              <br />
              Promoția trebuie finalizată în două zile. Această promoție nu se
              aplică la pariuri sportive. Te rugăm să citești cu atenție
              ceilalți termeni care se aplică.
            </Text>
            <Box h={10} />
            <Heading as="h6" size="sm" mb={5}>
              Turbo Thursday
            </Heading>
            <Text>
              Obțineți un bonus de reîncărcare de 50% până la 50 mBTC în zilele
              de joi, atunci când faci o depunere! Suma de depunere pentru
              aceasta are un minim de 1 mBTC și un maxim de 50 mBTC.
              <br />
              <br />
              Pentru a te califica, trebuie să fi făcut un depozit în ultimele
              trei zile și trebuie să activezi mai întâi bonusul înainte de a
              face depozitul. Pentru a primi creditul, vorbește cu serviciul de
              asistență clienți.
              <br />
              <br />
              Promoția este valabilă doar pentru jocurile de cazinou și are un
              termen de finalizare de două zile. Te rugăm să citești cu atenție
              ceilalți termeni care se aplică.
            </Text>
            <Box h={10} />
            <Heading as="h6" size="sm" mb={5}>
              Basketball Bingo
            </Heading>
            <Text>
              Dacă un jucător înscrie numărul de puncte indicat pe tricoul său,
              poți primi banii înapoi atunci când pariezi pe handicap la
              mecurile NBA cu marjă zero!
              <br />
              <br />
              Poți plasa un pariu pe handicap între 20$ și 100$ și poți primi
              banii înapoi dacă un jucător înscrie același număr de puncte cu
              numărul de pe tricou.
              <br />
              <br />
              Plasează un pariu pe handicap între 101,01$ și 200$ pentru a primi
              banii înapoi dacă doi jucători înscriu același număr de puncte cu
              numerele de pe tricou.
              <br />
              <br />
              Dacă trei sau mai mulți jucători își potrivesc numerele de tricou
              cu numărul de puncte marcate, îți vei primi banii înapoi la
              pariurile de tip handicap între 201,01$ și 300$.
              <br />
              <br />
              Sunt permise doar pariurile pe handicap plasate înainte de meci și
              este necesară o miză minimă de 20 de dolari. Te rugăm să citești
              cu atenție ceilalți termeni care se aplică.
              <br />
              <br />
              <b>
                {' '}
                În plus, Cloudbet are multe alte diverse promoții. Pentru a te
                asigura că nu pierzi nimic, stai cu ochii pe pagina de
                promoții./
              </b>
            </Text>
          </Container>
          <Container px={16} pb={10} fontSize={'18px'}>
            <SectionHeader
              title="Servicii pentru clienți 
"
            />
            <HStack>
              <AiFillStar color="gold" fontSize={'40px'} />
              <Heading size="md">9 / 10</Heading>{' '}
            </HStack>{' '}
            <Box h={10} />
            <Text>
              Asistența pentru clienți la Cloudbet este excelentă. Poți accesa
              cu ușurință serviciul lor de chat live în orice moment din meniul
              contului.
              <br />
              <br />
              Chiar și poziția ta în linie este indicată, astfel încât să știi
              cât timp s-ar putea să ai de așteptat. Ai posibilitatea de a alege
              să aștepți iar dacă ți se pare că durează prea mult poți să
              deschizi un nou bilet de asistență ulterior.
              <br />
              <br />
              Putem spune că serviciul lor de relații cu clienții este bun,
              deoarece a trebuit să așteptam doar un minut pentru a primi un
              răspuns amabil și util.
              <br />
              <br />
              Cloudbet support este disponibil și prin e-mail.
            </Text>
          </Container>

          <Container px={16} pb={10} fontSize={'18px'}>
            <SectionHeader title="Plăți" />
            <HStack>
              <AiFillStar color="gold" fontSize={'40px'} />
              <Heading size="md">9 / 10</Heading>{' '}
            </HStack>{' '}
            <Box h={10} />
            <Text as="b">
              Procedura de plată la Cloudbet este una bună.
              <br />
              <br />
              Acestia iși rezervă dreptul de a impune un comision de procesare
              de până la 5% pentru depozite și retrageri pentru a acoperi
              costurile asociate tranzacțiilor și orice cheltuieli pe care le
              suportă pentru depozitele din orice criptomonedă sau fiat pe care
              le retragi.
              <br />
              <br />
              În afară de acest dezavantaj, sistemul lor pentru depozite și
              retrageri este rapid și simplu.
            </Text>
            <Box h={10} />
            <Heading as="h6" size="sm" mb={5}>
              Depozite
            </Heading>
            <Text>Există mai multe opțiuni de depunere pe Cloudbet:</Text>
            <Divide title="Criptomonede" />
            <Flex
              justifyContent={'center'}
              margin={'auto'}
              gap={{
                base: '10',
                md: '16',
              }}
              wrap={'wrap'}
            >
              <img
                width="55"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/bitcoin-cryptocurrency.svg"
                draggable="false"
                alt="Bitcoin logo"
              />

              <img
                width="55"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/ethereum-cryptocurrency.svg"
                draggable="false"
                alt="Ethereum logo"
              />
              <img
                width="55"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/litecoin-cryptocurrency.svg"
                draggable="false"
                alt="Litecoin logo"
              />
              <img
                width="55"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/tron-cryptocurrency.svg"
                draggable="false"
                alt="Tron logo"
              />
              <img
                width="55"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/ripple.svg"
                draggable="false"
                alt="Ripple logo"
              />
              <img
                width="55"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/dogecoin-cryptocurrency.svg"
                draggable="false"
                alt="Doge coin logo"
              />
              <img
                width="55"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/eos-network-cryptocurrency.svg"
                draggable="false"
                alt="EOS coin logo"
              />
              <img
                width="55"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/tether-cryptocurrency.svg"
                draggable="false"
                alt="Tether logo"
              />

              <img
                width="55"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/binance-coin-cryptocurrency.svg"
                draggable="false"
                alt="Binance logo"
              />
              <img
                width="55"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/bch.svg"
                draggable="false"
                alt="Bitcoin cash logo"
              />
            </Flex>
            <Divide title="Ewallets" />
            <Flex
              justifyContent={'center'}
              margin={'auto'}
              gap={{
                base: '5',
                md: '6',
              }}
              wrap={'wrap'}
            >
              <img
                width="75"
                height="35"
                src="https://cryptobetcasino.ro/images/ewallets/metamask.svg"
                draggable="false"
                alt="Metamask logo"
              />
              <img
                width="75"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/binance-coin-cryptocurrency.svg"
                draggable="false"
                alt="Binance logo"
              />
            </Flex>
            <Box h={10} />
            <Heading as="h6" size="sm" mb={5}>
              Retrageri
            </Heading>
            <Text>
              Următoarele sunt opțiunile de retragere pentru Cloudbet:
            </Text>
            <Divide title="Criptomonede" />
            <Flex
              justifyContent={'center'}
              margin={'auto'}
              gap={{
                base: '10',
                md: '16',
              }}
              wrap={'wrap'}
            >
              <img
                width="55"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/bitcoin-cryptocurrency.svg"
                draggable="false"
                alt="Bitcoin logo"
              />

              <img
                width="55"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/ethereum-cryptocurrency.svg"
                draggable="false"
                alt="Ethereum logo"
              />
              <img
                width="55"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/litecoin-cryptocurrency.svg"
                draggable="false"
                alt="Litecoin logo"
              />
              <img
                width="55"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/tron-cryptocurrency.svg"
                draggable="false"
                alt="Tron logo"
              />
              <img
                width="55"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/ripple.svg"
                draggable="false"
                alt="Ripple logo"
              />
              <img
                width="55"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/dogecoin-cryptocurrency.svg"
                draggable="false"
                alt="Doge coin logo"
              />
              <img
                width="55"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/eos-network-cryptocurrency.svg"
                draggable="false"
                alt="EOS coin logo"
              />
              <img
                width="55"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/tether-cryptocurrency.svg"
                draggable="false"
                alt="Tether logo"
              />

              <img
                width="55"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/binance-coin-cryptocurrency.svg"
                draggable="false"
                alt="Binance logo"
              />
              <img
                width="55"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/bch.svg"
                draggable="false"
                alt="Bitcoin cash logo"
              />
            </Flex>
            <Box h={10} />
            <Divide title="Ewallets" />
            <Flex
              justifyContent={'center'}
              margin={'auto'}
              gap={{
                base: '5',
                md: '6',
              }}
              wrap={'wrap'}
            >
              <img
                width="75"
                height="35"
                src="https://cryptobetcasino.ro/images/ewallets/metamask.svg"
                draggable="false"
                alt="Metamask logo"
              />
              <img
                width="75"
                height="35"
                src="https://cryptobetcasino.ro/images/crypto/binance-coin-cryptocurrency.svg"
                draggable="false"
                alt="Binance logo"
              />
            </Flex>
            <Box h={10} />
            <Heading as="h6" size="sm" mb={5}>
              Limitele privind retragerile
            </Heading>
            <Text>
              La Cloudbet, banii pot fi scoși în orice moment. 0,001 BTC este
              suma minimă de retragere de la Cloudbet.
            </Text>
            <Box h={10} />
            <Heading as="h6" size="sm" mb={5}>
              Timp de rambursare
            </Heading>
            <Text></Text>
            <Text>
              Jucătorii au remarcat că recompensele se efectuează rapid, multe
              retrageri durează doar zece minute sau mai puțin. Retragerile din
              Fiat vor dura probabil mai mult.
            </Text>
          </Container>

          <Container px={16} pb={10} fontSize={'18px'}>
            <SectionHeader title="Recenzie Cloudbet: Observații finale" />
            <HStack>
              <AiFillStar color="gold" fontSize={'40px'} />
              <Heading size="md">9.17 / 10</Heading>{' '}
            </HStack>{' '}
            <Box h={10} />
            <Text as="b">
              Pe baza analizei Cloudbet, se poate concluziona că, Cloudbet este
              o platformă de pariuri online foarte apreciată, specializată în
              jocurile de noroc cu criptomonede. Oferă diverse opțiuni de
              pariuri sportive și o selecție extinsă de jocuri de cazinou.
              <br />
              <br />
              Cloudbet se remarcă prin interfața sa ușor de utilizat și
              compatibilitatea perfectă cu dispozitivele mobile, oferind
              utilizatorilor o experiență de joc convenabilă și plăcută.
              <br />
              <br />
              Cu diverse piețe sportive, cote competitive și funcții de pariuri
              live, Cloudbet este deosebit de atrăgător pentru pasionații de
              sport.
              <br />
              <br />
              În plus, platforma acordă prioritate securității, corectitudinii
              și jocurilor de noroc responsabile, asigurându-se că utilizatorii
              se pot implica cu încredere în activități de pariuri.
              <br />
              <br />
              În general, Cloudbet este o alegere de încredere pentru persoanele
              care doresc să participe la jocuri de noroc online folosind
              criptomonede. <br />
              <br />
              Sperăm că v-a plăcut să citiți această recenzie Cloudbet.
            </Text>{' '}
          </Container>
        </GridItem>

        <Show above="md">
          <GridItem colSpan={4}>
            <Box
              py={20}
              px={4}
              margin={'auto'}
              sx={{
                position: '-webkit-sticky',
                /* Safari */ position: 'sticky',
                top: '50',
              }}
              w="100%"
              textAlign={'left'}
            >
              <TableContainer p={3}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>
                        {' '}
                        <Heading size="xs"> Rating total</Heading>
                      </Th>
                      <Th>
                        <HStack>
                          <AiFillStar color="gold" fontSize={'20px'} />
                          <Heading size="xs">{9.17} / 10</Heading>{' '}
                        </HStack>{' '}
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>
                        <Text>Rating cazino</Text>
                      </Td>
                      <Td>{9.1} / 10</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Text>Rating pariuri sportive</Text>
                      </Td>
                      <Td>{9.25} / 10</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
              <HStack>
                <HiBadgeCheck
                  color="green"
                  display="inline"
                  fontSize={'20px'}
                />
                <Heading p={3} size={'xs'}>
                  Recomandăm utilizarea Cloudbet
                </Heading>
              </HStack>
              <Text fontSize={'lg'}>Disponibil în România 🇷🇴</Text>
            </Box>
          </GridItem>
        </Show>
      </Grid>
      <Box h={40} />
    </>
  );
}

export default ReviewCloudbet;
