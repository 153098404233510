import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import { Navbar } from '../components/Navbar/Navbar';
import { Footer } from '../components/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';

function Layout() {

  return (
    <Flex direction="column" flex="1">
      <Navbar />
      <Outlet />
      <Footer />
      <ScrollToTop />
    </Flex>
  );
}

export default Layout;
