import {
  Box,
  Flex,
  Heading,
  Img,
  Text,
  Container,
  Image,
} from '@chakra-ui/react';
import { CTABox } from '../components/CTABox/CtaBox';
import PageHeader from '../casinoComponents/PageHeader';

export const CustomArticleStyleOne = ({ articles }) => {
  return (
    <>
      <PageHeader title={articles.title} current={articles.title} />
      <div class="body-shape">
        <img src={require('../images/body-shape.png')} alt="shape" />
      </div>

      <div
        style={{ backgroundColor: 'rgb(8, 14, 55)', color: 'white' }}
        class="blog-section blog-single padding-top padding-bottom"
      >
        <div class="container">
          <div class="section-wrapper">
            <div class="row justify-content-center pb-15">
              <div class="col-lg-10 col-12">
                <article>
                  <div class="post-item-2">
                    <div class="post-inner">
                      <div class="post-thumb">
                        <img src={articles.headingImage} alt="blog" />
                      </div>
                      <div class="post-content">
                        <h2>{articles.title}</h2>
                        <ul class="lab-ul post-date">
                          {/* <li>
                            <span>
                              <i class="icofont-ui-calendar"></i> January 5,
                              2022 10:59 am
                            </span>
                          </li>
                          <li>
                            <span>
                              <i class="icofont-user"></i>
                              <a href="#">Rajib Raj</a>
                            </span>
                          </li> */}
                        </ul>

                        <blockquote class="single-quote mb-30">
                          <div class="quotes">
                            {articles.content[0].content}
                          </div>
                        </blockquote>
                        <Box h={articles.content[0].boxSize} />

                        {articles.content.slice(1).map((element, i) => {
                          return (
                            <>
                              <Heading size={element.size}>
                                {element.heading}
                              </Heading>
                              <Text fontSize="xl">{element.content}</Text>
                              <Box h={element.boxSize} />
                              {element.image && (
                                <div class="post-thumb mb-30">
                                  <Image src={element.image} />
                                </div>
                              )}
                              {element.video && (
                                <div class="post-thumb mb-30">
                                  <Image src={element.videoThumb} />
                                  <a
                                    href={element.video}
                                    class="play-btn"
                                    data-rel="lightcase"
                                  >
                                    <i class="icofont-play"></i>
                                    <span class="pluse_2"></span>
                                  </a>
                                </div>
                              )}
                            </>
                          );
                        })}

                        <div class="tags-area">
                          <ul class="tags lab-ul justify-content-center">
                            <li>
                              <a href="#">Charity</a>
                            </li>
                            <li>
                              <a href="#" class="active">
                                NoneProfit
                              </a>
                            </li>
                            <li>
                              <a href="#">Admission</a>
                            </li>
                            <li>
                              <a href="#">Exams</a>
                            </li>
                          </ul>
                          <ul class="share lab-ul justify-content-center">
                            <li>
                              <a href="#" class="facebook">
                                <i class="icofont-facebook"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#" class="dribble">
                                <i class="icofont-dribbble"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#" class="twitter">
                                <i class="icofont-twitter"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#" class="google">
                                <i class="icofont-google-plus"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="article-pagination">
                    <div class="prev-article">
                      <a href="#">
                        <i class="icofont-rounded-double-left"></i>Previous
                        Article
                      </a>
                      <p>
                        Evisculate Parallel Processes via Technica Sound Models
                        Authoritative
                      </p>
                    </div>
                    <div class="next-article">
                      <a href="#">
                        Next Article{' '}
                        <i class="icofont-rounded-double-right"></i>
                      </a>
                      <p>
                        Qvisculate Parallel Processes via Technica Sound Models
                        Authoritative
                      </p>
                    </div>
                  </div>
                  <div class="authors">
                    <div class="author-thumb">
                      <img
                        src="assets/images/blog/author/01.jpg"
                        alt="author"
                      />
                    </div>
                    <div class="author-content">
                      <h6>Rajib Ahmed</h6>
                      <p>
                        Competently conceptualize alternative synergy and
                        technically and niche markets. Efficiently impact
                        technically sound outsourcing rath tnclicks-and-mortar
                        best practices.
                      </p>
                      <div class="social-media">
                        <a href="#" class="twitter">
                          <i class="icofont-twitter"></i>
                        </a>
                        <a href="#" class="behance">
                          <i class="icofont-behance"></i>
                        </a>
                        <a href="#" class="instagram">
                          <i class="icofont-instagram"></i>
                        </a>
                        <a href="#" class="vimeo">
                          <i class="icofont-vimeo"></i>
                        </a>
                        <a href="#" class="linkedin">
                          <i class="icofont-linkedin"></i>
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* <div id="comments" class="comments">
								<h6 class="comment-title h7">02 Comment</h6>
								<ul class="lab-ul comment-list">
									<li class="comment" id="li-comment-1">
										<div class="com-item">
											<div class="com-thumb">
												<img alt="" src="assets/images/blog/author/02.jpg" srcset="assets/images/blog/author/02.jpg" class="avatar avatar-90 photo" height="90" width="90"/>
											</div>
											<div class="com-content">
												<div class="com-title">
													<div class="com-title-meta">
														<a href="#" rel="external nofollow" class="h7">Linsa Faith</a>
														<span> January 5, 2022 at 12:41 pm </span>
													</div>
													<span class="reply">
														<a rel="nofollow" class="comment-reply-link" href="#" aria-label="Reply to Masum"><i class="icofont-reply-all"></i>Reply</a>
													</span>
												</div>
												<p>The inner sanctuary, I throw myself down among the tall grass bye the
													trckli stream and, as I lie close to the earth</p>
												<div class="reply-btn"></div>
											</div>
										</div>
										<ul class="lab-ul comment-list">
											<li class="comment" id="li-comment-2">
												<div class="com-thumb">
													<img alt="" src="assets/images/blog/author/03.jpg" srcset="assets/images/blog/author/03.jpg" class="avatar avatar-90 photo" height="90" width="90"/>
												</div>
												<div class="com-content">
													<div class="com-title">
														<div class="com-title-meta">
															<a href="#" rel="external nofollow" class="h7">James Jusse</a>
															<span> January 5, 2022 at 12:41 pm </span>
														</div>
														<span class="reply">
															<a rel="nofollow" class="comment-reply-link" href="#" aria-label="Reply to Masum"><i class="icofont-reply-all"></i>Reply</a>
														</span>
													</div>
													<p>A wonderful serenity has taken possession of my entire soul, like these sweet mornings spring which I enjoy with my whole heart </p>
												</div>
											</li>
										</ul>
									</li>
								</ul>
							</div> */}

                  {/* <div id="respond" class="comment-respond">
								<h6 class="h7">Leave a Comment</h6>
								<div class="add-comment">
									<form action="#" method="post" id="commentform" class="comment-form">
										<input name="name" type="text" value="" placeholder="Name*">
										<input name="email" type="text" value="" placeholder="Email*">
										<input name="url" type="text" value="" placeholder="Website*" class="w-100">
										<textarea id="comment-reply" name="comment" rows="7" placeholder="Type Here Your Comment*"></textarea>
										<button type="submit" class="default-button"><span>Send Comment</span></button>
									</form>
								</div>
							</div> */}
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
