import {
  Box,
  ButtonGroup,
  Container,
  IconButton,
  Stack,
  Text,
  Image,
  HStack,
  SimpleGrid,
  Flex,
} from '@chakra-ui/react';
import {
  FaFacebook,
  FaGithub,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  FaTwitter,
} from 'react-icons/fa';
import { Logo } from '../Logo/Logo';
import { Link } from 'react-router-dom';
import '../../css/styles.css';
import image from '../../images/bg_footer.jpeg';

export const Footer = () => (
  // <Box bg="bg-accent" color="on-accent">
  //   <Container
  //     as="footer"
  //     role="contentinfo"
  //     py={{
  //       base: '12',
  //       md: '16',
  //     }}
  //   >
  //     <Stack
  //       spacing={{
  //         base: '4',
  //         md: '5',
  //       }}
  //     >
  //       <HStack justify="space-between" direction="row" align="center">
  //         <Link to="/">
  //           <Image width={'150px'} src={require('../../images/logo.png')} />
  //         </Link>

  //         <ButtonGroup variant="ghost-on-accent">
  //           <IconButton
  //             as="a"
  //             href="#"
  //             aria-label="Twitter"
  //             icon={<FaTwitter fontSize="1.25rem" />}
  //           />
  //           <IconButton
  //             as="a"
  //             href="#"
  //             aria-label="Facebook"
  //             icon={<FaFacebook fontSize="1.25rem" />}
  //           />
  //           <IconButton
  //             as="a"
  //             href="#"
  //             aria-label="Instagram"
  //             icon={<FaInstagram fontSize="1.25rem" />}
  //           />
  //           <IconButton
  //             as="a"
  //             href="#"
  //             aria-label="TikTok"
  //             icon={<FaTiktok fontSize="1.25rem" />}
  //           />
  //         </ButtonGroup>
  //       </HStack>
  //       <Text fontSize="sm" color="on-accent-subtle">
  //         &copy; {new Date().getFullYear()} Crypto Bet Casino. Toate drepturile
  //         rezervate.
  //       </Text>
  //     </Stack>
  //   </Container>
  // </Box>

  <footer
    className="footer-section"
    style={{ backgroundImage: `url(${image})`, color: 'white' }}
    // style="background-image: url(assets/images/footer/bg.jpg);"
  >
    <div className="footer-top">
      <div className="container">
        <div class="row g-3 justify-content-center g-lg-0">
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="footer-top-item lab-item">
              <div className="lab-inner">
                <div className="lab-thumb">
                  <img
                    src={require('../../images/icons/01.png')}
                    alt="Phone-icon"
                  />
                </div>
                <div className="lab-content">
                  <span>Telefon : +88012 345 678 912</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="footer-top-item lab-item">
              <div className="lab-inner">
                <div className="lab-thumb">
                  <img
                    src={require('../../images/icons/02.png')}
                    alt="email-icon"
                  />
                </div>
                <div className="lab-content">
                  <span>Email : business@cryptobetcasino.ro</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="footer-top-item lab-item">
              <div className="lab-inner">
                <div className="lab-thumb">
                  <img
                    src={require('../../images/icons/03.png')}
                    alt="location-icon"
                  />
                </div>
                <div className="lab-content">
                  <span>Locație: România </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-middle padding-top padding-bottom">
      <div className="container">
        <div class="row padding-lg-top">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="footer-middle-item-wrapper">
              <div className="footer-middle-item mb-lg-0">
                <div className="fm-item-title">
                  <h4>Top jackpot games</h4>
                </div>
                <div className="fm-item-content">
                  <div className="fm-item-widget lab-item">
                    <div className="lab-inner">
                      <div className="lab-thumb">
                        <a href="#">
                          {' '}
                          <img
                            src="assets/images/footer/01.jpg"
                            alt="footer-widget-img"
                            className="rounded-3"
                          />
                        </a>
                      </div>
                      <div className="lab-content">
                        <a href="blog-single.html">
                          <h6>free Poker Game</h6>
                        </a>
                        <p>
                          Poker: <b>$230</b>
                        </p>
                        <div className="rating">
                          <i className="icofont-ui-rating"></i>
                          <i className="icofont-ui-rating"></i>
                          <i className="icofont-ui-rating"></i>
                          <i className="icofont-ui-rating"></i>
                          <i className="icofont-ui-rating"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fm-item-widget lab-item">
                    <div className="lab-inner">
                      <div className="lab-thumb">
                        <a href="#">
                          <img
                            src="assets/images/footer/02.jpg"
                            alt="footer-widget-img"
                            className="rounded-3"
                          />
                        </a>
                      </div>
                      <div className="lab-content">
                        <a href="blog-single.html">
                          <h6>CLUB Poker Game</h6>
                        </a>
                        <p>
                          Poker: <b>$290</b>
                        </p>
                        <div className="rating">
                          <i className="icofont-ui-rating"></i>
                          <i className="icofont-ui-rating"></i>
                          <i className="icofont-ui-rating"></i>
                          <i className="icofont-ui-rating"></i>
                          <i className="icofont-ui-rating"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fm-item-widget lab-item">
                    <div className="lab-inner">
                      <div className="lab-thumb">
                        <a href="#">
                          <img
                            src="assets/images/footer/03.jpg"
                            alt="footer-widget-img"
                            className="rounded-3"
                          />
                        </a>
                      </div>
                      <div className="lab-content">
                        <a href="blog-single.html">
                          <h6>ROYAL Poker Game</h6>
                        </a>
                        <p>
                          Poker: <b>$330</b>
                        </p>
                        <div className="rating">
                          <i className="icofont-ui-rating"></i>
                          <i className="icofont-ui-rating"></i>
                          <i className="icofont-ui-rating"></i>
                          <i className="icofont-ui-rating"></i>
                          <i className="icofont-ui-rating"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-6 col-12">
            <div className="footer-middle-item-wrapper">
              <div className="footer-middle-item mb-lg-0">
                <div className="fm-item-title mb-4">
                  <img
                    width="300"
                    src={require('../../images/logo.png')}
                    alt="logo"
                  />
                </div>
                <div className="fm-item-content">
                  <p className="mb-4">
                    Crypto Bet Casino este ghidul tău de încredere în lumea
                    dinamică a pariurilor cu criptomonede. Noi explorăm, evaluăm
                    și evidențiem cele mai bune cazinouri cripto, oferindu-ți
                    cele mai recente știri, recenzii obiective și resurse
                    educaționale. Îți aducem cele mai atractive promoții și
                    ținem pasul cu tendințele emergente pentru a te ajuta să
                    navighezi cu succes și încredere în universul
                    cripto-gamingului.
                  </p>
                  <ul className="match-social-list d-flex flex-wrap align-items-center">
                    <li>
                      <a href="#">
                        <img
                          src="assets/images/match/social-1.png"
                          alt="vimeo"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src="assets/images/match/social-2.png"
                          alt="youtube"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src="assets/images/match/social-3.png"
                          alt="twitch"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-bottom">
      <div className="container">
        <div class="row">
          <div class="col-12">
            <div className="footer-bottom-content text-center">
              <p>
                &copy;2022 <a href="index.html">Casina</a> - All Rights
                Reserved. Developed by{' '}
                <a href="https://themeforest.net/user/codexcoder">CodexCoder</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);
