import { FaCalendar } from 'react-icons/fa';

export const stakeHighlits = {
  pro: [
    {
      title: 'Rakeback pe viață',
      description:
        'Folosind codul nostru exclusiv la înregistrare, vei primi 15% rakeback la toate pariurile tale, pentru totdeauna. ',
    },
    {
      title: 'Reputație foarte bună',
      description:
        'Recenzii perfecte ale jucătorilor pe internet și pe forumuri',
    },
    {
      title: 'Autentificare cu doi factori',
      description:
        'Această funcție este destul de rar întâlnită la cazinourile online și este foarte bună pentru a-ți menține contul în siguranță',
    },
    {
      title: 'Jocuri corecte',
      description: 'Jocurile, mizele și șansele de câștig sunt corecte.',
    },
    {
      title: 'Serviciu excelent pentru clienți',
      description:
        'Serviciul clienți îți va rezolva problemele prompt și poți comunica în mai multe limbi.',
    },
    {
      title: 'Varietate mare de cripto',
      description: 'Poți depune în peste 130 de criptomonede.',
    },
    {
      title: 'O mulțime de cote de pariuri',
      description:
        'Poți paria pe peste 100 de rezultate diferite la evenimente majore.',
    },
    {
      title: 'Jocuri de cazino pentru toată lumea',
      description:
        'Există 1.021 de jocuri disponibile, iar 16 sunt exclusive pentru Stake.com',
    },
    {
      title: 'Comunitate activă',
      description:
        'Jucătorii interacționează între ei în 8 limbi. Nu te vei simți singur!',
    },
    {
      title: 'Randament ridicat pentru jucător',
      description: 'RTP ajunge la 99% pentru unele jocuri.',
    },
    {
      title: 'Promoții grozave',
      description:
        'Promoții frecvente și atractive, inclusiv șansa de a câștiga un Lamborghini Huracan Evo.',
    },
  ],
  con: [
    {
      title: 'Cotele la pariuri sportive',
      description:
        'Alte case de pariuri pot oferi cote mai bune la evenimente majore.',
    },
    {
      title: 'Informații limitate',
      description: 'Restricții, taxe și detalii de plată greu de găsit.',
    },
    {
      title: 'Fără bonus de bun venit',
      description:
        'Dar așa reușesc să ofere plăți instantanee și promoții extraordinare.',
    },
  ],
};

export const stakeStats = [
  {
    icon: 0,
    label: 'Anul înființării',
    value: '2017',
  },
  {
    label: 'Firma',
    value: 'Medium Rare N.V.',
    icon: 1,
  },
  {
    label: 'Licență',
    value: 'Curaçao',
    icon: 2,
  },
  {
    label: 'Cryptomonede',
    value: 'BTC, ETH, XRP, +7',
    icon: 3,
  },
  {
    label: 'Limbi',
    value: 'Engleză, Spanionă, Rusă, +11',
    icon: 4,
  },
];

export const stakeFaqs = [
  {
    q: 'Este Stake.com legitim și sigur?',
    a: 'Stake.com este legal. Este licențiat în Curacao și a fost fondat în 2017. Este un cazinou sigur, cu jocuri dovedite echitabile, 2FA și apartenență la Crypto Gambling Foundation.',
  },
  {
    q: 'Unde este Stake.com legal?',
    a: 'Miza este legală atâta timp cât nu locuiți într-una dintre țările interzise enumerate și dacă aveți vârsta majoratului în jurisdicția dumneavoastră.',
  },
  {
    q: 'Este disponibil jocul anonim pe Stake.com?',
    a: 'Stake.com va colecta informațiile dvs. personale pentru verificarea cunoașterii clientului (KYC), precum și pentru a vă procesa înregistrarea.',
  },
  {
    q: 'Cum se joacă pe Stake.com?',
    a: 'Atâta timp cât nu locuiți într-o țară interzisă, puteți juca pe Stake.com înregistrându-vă un cont, efectuând un depozit și accesând jocul preferat pentru a începe să jucați.',
  },
  {
    q: 'Cât de ușor este să retragi câștigurile de pe Stake?',
    a: 'Stake este un cazinou crypto-only, astfel încât utilizatorii pot retrage fonduri doar într-un portofel crypto. Retragerile sunt instantanee, după timpul obișnuit de confirmare asociat cu diferitele blockchains.',
  },
  {
    q: 'Care este retragerea minimă și maximă pe Stake?',
    a: 'Deși nu există sume maxime de plată, există sume minime care variază în funcție de tipul de criptomonedă pe care îl utilizezi. De asemenea, este posibil să existe o mică taxă pentru retrageri, în funcție de tipul de criptomonedă pe care îl utilizezi.',
  },
  {
    q: 'Care este bonusul de bun venit de la Stake??',
    a: 'Stake.com nu oferă un bonus de bun venit. Cu toate acestea, oferă promoții sportive și de cazinou.',
  },
  {
    q: 'Cum este serviciul clienți de la Stake?',
    a: 'Stake oferă un chat live 24/7 excelent, un forum de suport și o adresă de e-mail pentru serviciul clienți. Stake.com poate fi contactat și prin intermediul Facebook, Twitter și Instagram.',
  },
  {
    q: 'Cum pot depune o plângere împotriva Stake.com?',
    a: 'Dacă aveți o plângere, poți posta pe forumul de asistență sau poți contacta serviciul de asistență pentru clienți.',
  },
  {
    q: 'Pot să joc la Stake.com cu un VPN?',
    a: 'Deși, din punct de vedere tehnic, puteți folosi un VPN din motive de confidențialitate, dacă intenționezi să folosești un VPN pentru a juca într-o jurisdicție interzisă, acest lucru nu este permis.',
  },
  {
    q: 'Există un cod promoțional la Stake.com?',
    a: "Da! Folosind codul nostru exclusiv 'CRYPTOBETCASINO' atunci când te înregistrezi, vei primi 15% rakeback la toate pariurile tale, pentru totdeauna.",
  },
];

export const maxPayouts = {
  payoutOption: [
    'Bitcoin',
    'Bitcoin Cash',
    'Ethereum',
    'Litecoin',
    'Ripple',
    'TRON',
    'EOS',
    'DOGE',
  ],
  min: [
    '0.002 BTC',
    '0.05 BCH',
    '0.1 ETH',
    '0.25 LTC',
    '50 XRP',
    '750 TRX',
    '4 EOS',
    '5000 DOGE',
  ],
  fee: [
    '0.0002 BTC',
    '0.00001 BCH',
    '0.0027 ETH',
    '0.0005 LTC',
    '0.0001 XRP',
    '1 TRX',
    '0.1 EOS',
    '1.5 DOGE',
  ],
};
