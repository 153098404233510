export const bitcasinoStats = [
  {
    icon: 0,
    label: 'Anul înființării',
    value: '2014',
  },
  {
    label: 'Firma',
    value: 'Moon Technologies B.V.',
    icon: 1,
  },
  {
    label: 'Licență',
    value: 'Curaçao',
    icon: 2,
  },
  {
    label: 'Cryptomonede',
    value: 'BTC, ETH, XRP, +8',
    icon: 3,
  },
  {
    label: 'Limbi',
    value: 'Engleză, Spanionă, Franceză, +9',
    icon: 4,
  },
];

export const bitcasinoHighlits = {
  pro: [
    {
      title: 'O reputație pozitivă',
      description:
        'Clienții, în general, au postat doar recenzii pozitive pe platformă.',
    },
    {
      title: 'Toată lumea este binevenită să joace jocurile de cazino',
      description:
        'Există un total de peste 2000 de jocuri din care poți alege, inclusiv sloturi, jocuri de masă și un cazinou live.',
    },
    {
      title: 'Suport pentru clienți care este util',
      description:
        'Poți obține răspunsuri instant contactând echipa de asistență prin chat live.',
    },
    {
      title: 'O mare varietate de criptomonede sunt disponibile',
      description: 'Poți depune în multe criptomonede.',
    },
    {
      title: 'O mulțime de cote de pariuri',
      description:
        'Poți paria pe peste 100 de rezultate diferite la evenimente majore.',
    },
    {
      title: 'O selecție de bonusuri oferite de cazinou.',
      description: 'Peste zece promoții active ale site-ului.',
    },
    {
      title: 'Cotele de pariuri sportive',
      description:
        'Site-ul de pariuri asociat Bitcasino este Sportsbet.io, iar cotele sunt mai bune în comparație cu normele din industrie.',
    },
  ],
  con: [
    {
      title:
        'Doar utilizatorii care s-au înregistrat în prealabil au acces la funcția de chat live',
      description:
        'Dacă un jucător nu se înregistrează la BitCasino, nu va putea intra în contact cu serviciul de asistență pentru clienți; în consecință, nu poți pune întrebări înainte de a te înregistra.',
    },
    {
      title: 'Nu există un bonus de bun venit.',
      description:
        'Cu toate acestea, există o mare varietate de concursuri și promoții din care poți alege.',
    },
  ],
};
