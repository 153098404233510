import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  HStack,
  IconButton,
  Image,
  useBreakpointValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Text,
  Spacer,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { FiMenu } from 'react-icons/fi';
import { Link } from 'react-router-dom';

export const Navbar = () => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });

  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  return (
    // <Box
    //   as="section"
    //   sx={{
    //     position: '-webkit-sticky',
    //     /* Safari */ position: 'sticky',
    //     top: '0',
    //     zIndex: 1,
    //   }}
    // >
    //   <Box as="nav" bg="bg-surface" boxShadow="sm">
    //     <Container
    //       py={{
    //         base: '4',
    //         lg: '5',
    //       }}
    //     >
    //       <HStack spacing="10" justify="space-between">
    //         <Link to="/">
    //           <Image
    //             width={'150px'}
    //             src={require('../../images/logo_red.png')}
    //           />{' '}
    //         </Link>
    //         {isDesktop ? (
    //           <Flex
    //             justifyContent={'center'}
    //             margin={'auto'}
    //             gap={{
    //               base: '5',
    //               md: '6',
    //             }}
    //           >
    //             {[
    //               {
    //                 name: 'Top Case de Pariuri Crypto',
    //                 items: [
    //                   { name: 'Stake.com', href: 'review-stake' },
    //                   { name: 'Cloudbet', href: 'review-cloudbet' },
    //                   { name: 'Bitcasino', href: 'review-bitcasino' },
    //                 ],
    //               },
    //               {
    //                 name: 'Top Cazinouri Crypto',
    //                 items: [
    //                   { name: 'Stake.com', href: 'review-stake' },
    //                   { name: 'Cloudbet', href: 'review-cloudbet' },
    //                   { name: 'Bitcasino', href: 'review-bitcasino' },
    //                 ],
    //               },
    //               { name: 'Resurse', items: [] },
    //             ].map(item => (
    //               <Menu>
    //                 <MenuButton rightIcon={<FaChevronDown />}>
    //                   <Text as="b">{item.name}</Text>
    //                 </MenuButton>
    //                 <MenuList>
    //                   {item.items.map((element, key) => (
    //                     <MenuItem as="a" href={element.href}>
    //                       {element.name}
    //                     </MenuItem>
    //                   ))}
    //                 </MenuList>
    //               </Menu>
    //             ))}
    //           </Flex>
    //         ) : (
    //           <IconButton
    //             variant="ghost"
    //             icon={<FiMenu fontSize="1.25rem" />}
    //             aria-label="Open Menu"
    //           />
    //         )}
    //       </HStack>
    //     </Container>
    //   </Box>
    // </Box>
    // <header
    //   classNameName="header-section"
    //   style={{
    //     position: '-webkit-sticky',
    //     /* Safari */ position: 'sticky',
    //     top: '0',
    //     zIndex: 99,
    //     backgroundColor: showTopBtn === false && '#0e0e0e',
    //   }}
    // >
    //   <Box as="section" py={5} classNameName="container ">
    //     {isDesktop ? (
    //       <Flex justifyContent={'center'}>
    //         <div style={{ maxWidth: '150px' }}>
    //           <a href="index.html">
    //             <Image
    //               width={'150px'}
    //               src={require('../../images/logo_white.png')}
    //             />{' '}
    //           </a>
    //         </div>
    //         <Spacer />
    //         <div classNameName="menu-area">
    //           <ul classNameName="menu">
    //             <li>
    //               <a href="#0">Home</a>
    //               <ul classNameName="submenu">
    //                 <li>
    //                   <a href="index.html" classNameName="active">
    //                     Home Page One
    //                   </a>
    //                 </li>
    //                 <li>
    //                   <a href="index-2.html">Home Page Two</a>
    //                 </li>
    //               </ul>
    //             </li>
    //             <li>
    //               <a href="#0">Features</a>
    //               <ul classNameName="submenu">
    //                 <li>
    //                   <a href="about.html">About</a>
    //                 </li>
    //                 <li>
    //                   <a href="jaqpot.html">Casino Jackpot</a>
    //                 </li>
    //                 <li>
    //                   <a href="#0">games</a>
    //                   <ul classNameName="submenu">
    //                     <li>
    //                       <a href="game-list.html">game list 1</a>
    //                     </li>
    //                     <li>
    //                       <a href="game-list2.html">game list 2</a>
    //                     </li>
    //                   </ul>
    //                 </li>
    //                 <li>
    //                   <a href="partners.html">partners</a>
    //                 </li>
    //                 <li>
    //                   <a href="pricing-plan.html">Pricing Plan</a>
    //                 </li>

    //                 <li>
    //                   <a href="#0">accounts</a>
    //                   <ul classNameName="submenu">
    //                     <li>
    //                       <a href="login.html">login</a>
    //                     </li>
    //                     <li>
    //                       <a href="signup.html">signup</a>
    //                     </li>
    //                   </ul>
    //                 </li>
    //                 <li>
    //                   <a href="#0">Shop</a>
    //                   <ul classNameName="submenu">
    //                     <li>
    //                       <a href="shop.html">shop</a>
    //                     </li>
    //                     <li>
    //                       <a href="shop-single.html">Shop Details</a>
    //                     </li>
    //                     <li>
    //                       <a href="cart-page.html">Cart Page</a>
    //                     </li>
    //                   </ul>
    //                 </li>
    //                 <li>
    //                   <a href="404.html">404 Page</a>
    //                 </li>
    //               </ul>
    //             </li>
    //             <li>
    //               <a href="tournament.html">Tournament</a>
    //             </li>
    //             <li>
    //               <a href="#0">Blog</a>
    //               <ul classNameName="submenu">
    //                 <li>
    //                   <a href="blog.html">Blog</a>
    //                 </li>
    //                 <li>
    //                   <a href="blog-2.html">Blog 2</a>
    //                 </li>
    //                 <li>
    //                   <a href="blog-single.html">Blog Single</a>
    //                 </li>
    //               </ul>
    //             </li>
    //             <li>
    //               <a href="contact.html">Contact</a>
    //             </li>
    //           </ul>
    //         </div>
    //       </Flex>
    //     ) : (
    //       <>
    //         {' '}
    //         <div classNameName="header-bar d-lg-none">
    //           <span></span>
    //           <span></span>
    //           <span></span>
    //         </div>
    //         <div classNameName="ellepsis-bar d-lg-none">
    //           <i classNameName="icofont-info-square"></i>
    //         </div>
    //       </>
    //     )}
    //   </Box>
    // </header>
    <header className="header-section">
      <div className="container">
        <div className="header-holder d-flex flex-wrap justify-content-between align-items-center">
          <div className="brand-logo d-none d-lg-inline-block">
            <div className="logo">
              <Link to="/">
                <Image width={'150px'} src={require('../../images/logo.png')} />{' '}
              </Link>
            </div>
          </div>
          <div className="header-menu-part">
            <div className="header-top">
              <div className="header-top-area">
                <ul className="left">
                  <li>
                    <i className="icofont-ui-call"></i>{' '}
                    <span>+800-123-4567 6587</span>
                  </li>
                  <li>
                    <i className="icofont-location-pin"></i> Beverley, New York
                    224 USA
                  </li>
                </ul>
                <ul className="social-icons d-flex align-items-center">
                  <li>
                    <a href="#" className="fb">
                      <i className="icofont-facebook-messenger"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="twitter">
                      <i className="icofont-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="vimeo">
                      <i className="icofont-vimeo"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="skype">
                      <i className="icofont-skype"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="rss">
                      <i className="icofont-rss-feed"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="header-bottom">
              <div className="header-wrapper justify-content-lg-end">
                <div className="mobile-logo d-lg-none">
                  <a href="index.html">
                    <img src="assets/images/logo/logo.png" alt="logo" />
                  </a>
                </div>
                <div className="menu-area">
                  <ul className="menu">
                    <li>
                      <a href="#0">Home</a>
                      <ul className="submenu">
                        <li>
                          <a href="index.html" className="active">
                            Home Page One
                          </a>
                        </li>
                        <li>
                          <a href="index-2.html">Home Page Two</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#0">Features</a>
                      <ul className="submenu">
                        <li>
                          <a href="about.html">About</a>
                        </li>
                        <li>
                          <a href="jaqpot.html">Casino Jackpot</a>
                        </li>
                        <li>
                          <a href="#0">games</a>
                          <ul className="submenu">
                            <li>
                              <a href="game-list.html">game list 1</a>
                            </li>
                            <li>
                              <a href="game-list2.html">game list 2</a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="partners.html">partners</a>
                        </li>
                        <li>
                          <a href="pricing-plan.html">Pricing Plan</a>
                        </li>

                        <li>
                          <a href="#0">accounts</a>
                          <ul className="submenu">
                            <li>
                              <a href="login.html">login</a>
                            </li>
                            <li>
                              <a href="signup.html">signup</a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="#0">Shop</a>
                          <ul className="submenu">
                            <li>
                              <a href="shop.html">shop</a>
                            </li>
                            <li>
                              <a href="shop-single.html">Shop Details</a>
                            </li>
                            <li>
                              <a href="cart-page.html">Cart Page</a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="404.html">404 Page</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="tournament.html">Tournament</a>
                    </li>
                    <li>
                      <a href="#0">Blog</a>
                      <ul className="submenu">
                        <li>
                          <a href="blog.html">Blog</a>
                        </li>
                        <li>
                          <a href="blog-2.html">Blog 2</a>
                        </li>
                        <li>
                          <a href="blog-single.html">Blog Single</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="contact.html">Contact</a>
                    </li>
                  </ul>

                  <div className="header-bar d-lg-none">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  <div className="ellepsis-bar d-lg-none">
                    <i className="icofont-info-square"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
