import React from 'react';
import '../css/styles.css';
import image2 from '../images/bg_games.jpeg';
import image3 from '../images/08.png';
import { Flex } from '@chakra-ui/react';

function Cta() {
  return (
    <div
      class="game game-style3 overflow-hidden"
      style={{ backgroundImage: `url(${image2})` }}
    >
      <div class="container">
        <div class="section-wrapper">
          {/* <SimpleGrid spacing="40px" columns={[1, 2, 2]}> */}
          <div class="row justify-content-center g-4 align-items-center">
            <div class="col-lg-6 col-12">
              <div class="game__thumb">
                <img src={image3} alt="game-thumb" />
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="game__content">
                <div class="section-header text-start">
                  <h2> Recomandarea Zilei</h2>
                  <p style={{ fontSize: '18px' }}>
                    Assertively communicate an expanded array of mindshare
                    rather than diverse technologies for magnetic applications
                    eamlessly virtual then ussertively communcate an expanded
                    array of mindshare rather think wiverse technologies for
                    magnetic applications eamlessly virtual that conveniently
                    monetize synergistic human capital
                  </p>
                  <a href="game-list.html" class="default-button">
                    <span>
                      Browse All Matches <i class="icofont-circled-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cta;
