import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  GridItem,
  Stack,
  Img,
} from '@chakra-ui/react';
import { Navbar } from '../../components/Navbar/Navbar';
import { posts } from '../../components/BlogPost/data';
import { BlogPost } from '../../components/BlogPost/BlogPost';
import { Cards } from '../../components/Cards/Cards';
import { Footer } from '../../components/Footer/Footer';
import { Carousel } from '../../components/Carousel/Carousel';
import { Gallery } from '../../components/Carousel/Gallery';
import { images } from '../../components/Carousel/_data';

import Hero from '../../casinoComponents/Hero';
import About from '../../casinoComponents/About';
import CasinoRank from '../../casinoComponents/CasinoRank';
import CasinoTops from '../../casinoComponents/CasinoTops';
import Cta from '../../casinoComponents/Cta';
import BlogArticles from '../../casinoComponents/BlogArticles';
import '../../css/styles.css';
import '../../css/bootstrap.min.css';
import '../../css/icofont.min.css';

import DailyBonuses from '../../casinoComponents/DailyBonuses';

function Homepage() {
  return (
    <>
      <Hero />

      <About />
      <DailyBonuses />
      <CasinoTops />

      <CasinoRank />
      <BlogArticles />
      <Cta />

      {/* <Gallery images={images} />

      <Cards />
    

      <Container mb={20}>
        <a href="https://cldbt.cloud/go/en/bitcoin-bonus?af_token=7b91933580d36bd70ade408c8c9060b6">
          <Img
            style={{ margin: 'auto' }}
            src={require('../../images/cloudbet-casino-728x90.gif')}
            alt="Cloud bet promo"
          />
        </a>
      </Container> */}

      {/* <Newsletter /> */}
    </>
  );
}

export default Homepage;
