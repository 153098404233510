import React from 'react';
import PageHeader from '../../casinoComponents/PageHeader';
import FullBlogSection from '../../casinoComponents/FullBlogSection';

function Blog() {
  return (
    <>
      <PageHeader  current="Blog" title="Resurse educaționale" />
      <FullBlogSection />
    </>
  );
}

export default Blog;
