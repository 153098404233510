import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { theme } from './theme';
import '@fontsource/barlow/500.css';
import '@fontsource/oswald/700.css';

import Layout from './Layout/Layout';
import Homepage from './pages/Homepage/Homepage';

import { CustomArticleStyleOne } from './articles/CustomArticleStyleOne';

import { articleOneData } from './articles/data/articleOneData.js';
import { articleTwoData } from './articles/data/articleTwoData.js';
import { articleThreeData } from './articles/data/articleThreeData.js';
import { articleFourData } from './articles/data/articleFourData.js';
import { articleFiveData } from './articles/data/articleFiveData.js';
import { articleSixData } from './articles/data/articleSixData.js';

import ReviewStake from './pages/Reviews/ReviewStake';
import ReviewBitcasino from './pages/Reviews/ReviewBitcasino';
import ReviewCloudbet from './pages/Reviews/ReviewCloudbet';
import Blog from './pages/Blog/Blog';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Homepage />} />
            <Route
              path="introducere-in-cazinourile-crypto"
              element={<CustomArticleStyleOne articles={articleOneData} />}
            />
            <Route
              path="cum-sa-alegi-un-cazinou-crypto"
              element={<CustomArticleStyleOne articles={articleTwoData} />}
            />
            <Route
              path="intelegerea-pariurilor-crypto"
              element={<CustomArticleStyleOne articles={articleThreeData} />}
            />
            <Route
              path="cum-sa-te-intregistrezi-la-cazino-crypto"
              element={<CustomArticleStyleOne articles={articleFourData} />}
            />
            <Route
              path="securitatea-in-cazinourile-crypto"
              element={<CustomArticleStyleOne articles={articleFiveData} />}
            />
            <Route
              path="ce-sunt-bonusurile-de-bun-venit-in-cazinourile-crypto"
              element={<CustomArticleStyleOne articles={articleSixData} />}
            />
            <Route path="review-stake" element={<ReviewStake />} />
            <Route path="review-bitcasino" element={<ReviewBitcasino />} />
            <Route path="review-cloudbet" element={<ReviewCloudbet />} />
            <Route path="blog" element={<Blog />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
