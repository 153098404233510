import React from 'react';
import '../css/styles.css';
import { Grid, GridItem, SimpleGrid } from '@chakra-ui/react';
import image from '../images/jaqport-bg.jpeg';

function CasinoTops() {
  return (
    <div
      className="jaqport padding-top padding-bottom"
      style={{ backgroundImage: `url(${image})` }}
      //   style="background-image: url(assets/images/jaqport/jaqport-bg.jpg);"
    >
      <div className="container">
        <div className="section-header">
          <h2>TOP SITE-URI CRYPTO</h2>
          <p>
            Cazinouri si Case de Pariuri Online Crypto. Descoperă Cele mai bune
            site-uri de jocuri de noroc si cazinouri crypto din România
          </p>
        </div>
        <div className="section-wrapper">
          <div class="row">
            <div className="col-lg-4 col-12">
              <div className="jaqport__left">
                <div className="jaqport__title">
                  <div className="jaqport__title-title">
                    <h4>top site-uri crypto</h4>
                  </div>
                </div>
                <div className="jaqport__body">
                  <ul>
                    <li className="jaqport__gamelist">
                      <div className="jaqport__gamelist-left">
                        <p>1.Christopher</p>
                      </div>

                      <div className="jaqport__gamelist-right">
                        <h6>$2365.96</h6>
                      </div>
                    </li>
                    <li className="jaqport__gamelist">
                      <div className="jaqport__gamelist-left">
                        <p>2.Mark</p>
                      </div>

                      <div className="jaqport__gamelist-right">
                        <h6>$2365.96</h6>
                      </div>
                    </li>
                    <li className="jaqport__gamelist">
                      <div className="jaqport__gamelist-left">
                        <p>3.Craigr</p>
                      </div>

                      <div className="jaqport__gamelist-right">
                        <h6>$2365.96</h6>
                      </div>
                    </li>
                    <li className="jaqport__gamelist">
                      <div className="jaqport__gamelist-left">
                        <p>4.Philip</p>
                      </div>

                      <div className="jaqport__gamelist-right">
                        <h6>$2365.96</h6>
                      </div>
                    </li>
                    <li className="jaqport__gamelist">
                      <div className="jaqport__gamelist-left">
                        <p>5.Jimmy</p>
                      </div>

                      <div className="jaqport__gamelist-right">
                        <h6>$2365.96</h6>
                      </div>
                    </li>
                    <li className="jaqport__gamelist">
                      <div className="jaqport__gamelist-left">
                        <p>6.Arthur</p>
                      </div>

                      <div className="jaqport__gamelist-right">
                        <h6>$2365.96</h6>
                      </div>
                    </li>
                    <li className="jaqport__gamelist">
                      <div className="jaqport__gamelist-left">
                        <p>7.Jaime</p>
                      </div>

                      <div className="jaqport__gamelist-right">
                        <h6>$2365.96</h6>
                      </div>
                    </li>
                    <li className="jaqport__gamelist">
                      <div className="jaqport__gamelist-left">
                        <p>8.Perry</p>
                      </div>

                      <div className="jaqport__gamelist-right">
                        <h6>$2365.96</h6>
                      </div>
                    </li>
                    <li className="jaqport__gamelist">
                      <div className="jaqport__gamelist-left">
                        <p>9.Harold</p>
                      </div>

                      <div className="jaqport__gamelist-right">
                        <h6>$2365.96</h6>
                      </div>
                    </li>
                    <li className="jaqport__gamelist">
                      <div className="jaqport__gamelist-left">
                        <p>10.Jerry</p>
                      </div>
                      <div className="jaqport__gamelist-middle">
                        <img
                          src="assets/images/jaqport/country/10.jpg"
                          alt="jaqport-thumb"
                          className="rounded-circle"
                        />
                      </div>
                      <div className="jaqport__gamelist-right">
                        <h6>$2365.96</h6>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-12">
              <div className="jaqport__right">
                <div className="jaqport__top">
                  <div className="jaqport__title">
                    <div className="jaqport__title-title">
                      <h4>Top Cazinouri Online Crypto</h4>
                    </div>
                  </div>
                  <div className="jaqport__body">
                    <div class="row g-4 justify-content-center">
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="game__item item-layer">
                          <div className="game__inner text-center p-0">
                            <div className="game__thumb mb-0">
                              <img
                                src="assets/images/jaqport/01.jpg"
                                alt="game-img"
                                className="rounded-3 w-100"
                              />
                            </div>
                            <div className="game__overlay">
                              <h4>poker games</h4>
                              <p>$1,23,002</p>
                              <a href="#" className="default-button">
                                <span>
                                  play now{' '}
                                  <i className="icofont-circled-right"></i>
                                </span>{' '}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="game__item item-layer">
                          <div className="game__inner text-center p-0">
                            <div className="game__thumb mb-0">
                              <img
                                src="assets/images/jaqport/02.jpg"
                                alt="game-img"
                                className="rounded-3 w-100"
                              />
                            </div>
                            <div className="game__overlay">
                              <h4>poker games</h4>
                              <p>$1,23,002</p>
                              <a href="#" className="default-button">
                                <span>
                                  play now{' '}
                                  <i className="icofont-circled-right"></i>
                                </span>{' '}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="game__item item-layer">
                          <div className="game__inner text-center p-0">
                            <div className="game__thumb mb-0">
                              <img
                                src="assets/images/jaqport/03.jpg"
                                alt="game-img"
                                className="rounded-3 w-100"
                              />
                            </div>
                            <div className="game__overlay">
                              <h4>poker games</h4>
                              <p>$1,23,002</p>
                              <a href="#" className="default-button">
                                <span>
                                  play now{' '}
                                  <i className="icofont-circled-right"></i>
                                </span>{' '}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="jaqport__bottom">
                  <div className="jaqport__title">
                    <div className="jaqport__title-title">
                      <h4>Top Case de Pariuri Online Crypto</h4>
                    </div>
                  </div>
                  <div className="jaqport__body">
                    <div class="row g-4 justify-content-center">
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="game__item item-layer">
                          <div className="game__inner text-center p-0">
                            <div className="game__thumb mb-0">
                              <img
                                src="assets/images/jaqport/04.jpg"
                                alt="game-img"
                                className="rounded-3 w-100"
                              />
                            </div>
                            <div className="game__overlay">
                              <h4>poker games</h4>
                              <p>$1,23,002</p>
                              <a href="#" className="default-button">
                                <span>
                                  play now{' '}
                                  <i className="icofont-circled-right"></i>
                                </span>{' '}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="game__item item-layer">
                          <div className="game__inner text-center p-0">
                            <div className="game__thumb mb-0">
                              <img
                                src="assets/images/jaqport/05.jpg"
                                alt="game-img"
                                className="rounded-3 w-100"
                              />
                            </div>
                            <div className="game__overlay">
                              <h4>poker games</h4>
                              <p>$1,23,002</p>
                              <a href="#" className="default-button">
                                <span>
                                  play now{' '}
                                  <i className="icofont-circled-right"></i>
                                </span>{' '}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-12">
                        <div className="game__item item-layer">
                          <div className="game__inner text-center p-0">
                            <div className="game__thumb mb-0">
                              <img
                                src="assets/images/jaqport/06.jpg"
                                alt="game-img"
                                className="rounded-3 w-100"
                              />
                            </div>
                            <div className="game__overlay">
                              <h4>poker games</h4>
                              <p>$1,23,002</p>
                              <a href="#" className="default-button">
                                <span>
                                  play now{' '}
                                  <i className="icofont-circled-right"></i>
                                </span>{' '}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CasinoTops;
