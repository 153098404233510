import React from 'react';
import '../css/styles.css';
import '../css/bootstrap.min.css';
import image from '../images/bg_header.jpeg';

function PageHeader(props) {
  return (
    <section
      className="pageheader-section"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="container">
        <div className="section-wrapper text-center text-uppercase">
          <h2 className="pageheader-title">{props.title}</h2>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <a href="/">Acasă</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {props.current}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </section>
  );
}

export default PageHeader;
