export const posts = [
  {
    id: '1',
    title: 'Introducere în cazinourile crypto',
    image: 'https://alevla.com/media/cryptobetcasino/blogBanners/1.png',
    category: 'Cazino',
    URL: 'introducere-in-cazinourile-crypto',
  },
  {
    id: '2',
    title: 'Cum să alegi un cazinou crypto',
    image: 'https://alevla.com/media/cryptobetcasino/blogBanners/2.png',
    category: 'Cazino',
    URL: 'cum-sa-alegi-un-cazinou-crypto',
  },
  {
    id: '3',
    title: 'Înțelegerea pariurilor crypto',
    image: 'https://alevla.com/media/cryptobetcasino/blogBanners/3.png',
    category: 'Pariuri',
    URL: 'intelegerea-pariurilor-crypto',
  },
  {
    id: '4',
    title: 'Cum să joci la un cazinou crypto',
    image: 'https://alevla.com/media/cryptobetcasino/blogBanners/4.png',
    category: 'Tutorial',
    URL: 'cum-sa-te-intregistrezi-la-cazino-crypto',
  },
  {
    id: '5',
    title: 'Securitatea în cazinourile crypto',
    image: 'https://alevla.com/media/cryptobetcasino/blogBanners/5.png',
    category: 'Securitate',
    URL: 'securitatea-in-cazinourile-crypto',
  },
  {
    id: '6',
    title: 'Ce sunt bonusurile de bun venit?',
    image: 'https://alevla.com/media/cryptobetcasino/blogBanners/6.png',
    category: 'Bonusuri',
    URL: 'ce-sunt-bonusurile-de-bun-venit-in-cazinourile-crypto',
  },
];
