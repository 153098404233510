import { Box, Center, Heading, Stack, Text } from '@chakra-ui/react';
import { AiFillCalendar } from 'react-icons/ai';
import {
  FaAward,
  FaBuilding,
  FaCalendar,
  FaCoins,
  FaLanguage,
} from 'react-icons/fa';
const icons = [
  <FaCalendar fontSize={'40px'} />,
  <FaBuilding fontSize={'40px'} />,
  <FaAward fontSize={'40px'}/>,
  <FaCoins fontSize={'40px'}/>,
  <FaLanguage fontSize={'40px'} />,
];

export const Stat = props => {
  const { label, value, icon, ...boxProps } = props;
  console.log(icon);
  return (
    <Box
      px={{
        base: '4',
        md: '6',
      }}
      py={{
        base: '5',
        md: '6',
      }}
      bg="bg.surface"
      borderRadius="lg"
      boxShadow="sm"
      textAlign={'center'}
      minWidth={{base: "100%", md:'250px', lg: '350px'}}
      {...boxProps}
    >
      <Stack>
        <Center>{icons[parseInt(icon)]}</Center>

        <Text textStyle="sm" color="fg.muted">
          {label}
        </Text>
        <Text fontSize={'20px'}>{value}</Text>
      </Stack>
    </Box>
  );
};
