export const cloudbetStats = [
  {
    icon: 0,
    label: 'Anul înființării',
    value: '2013',
  },
  {
    label: 'Firma',
    value: 'Halcyon Super Holdings BV',
    icon: 1,
  },
  {
    label: 'Licență',
    value: 'Curaçao',
    icon: 2,
  },
  {
    label: 'Cryptomonede',
    value: 'BTC, ETH, XRP, +24',
    icon: 3,
  },
  {
    label: 'Limbi',
    value: 'Engleză, Spanionă, Rusă, +14',
    icon: 4,
  },
];

export const cloudbetHighlits = {
  pro: [
    {
      title: 'Joacă anonim',
      description: 'Înscrie-te folosind doar adresa ta de e-mail.',
    },
    {
      title: 'Jocuri care se dovedesc a fi corecte',
      description: 'Jocurile sunt testate RNG.',
    },
    {
      title: 'Website securizat',
      description:
        'Certificatul SSL și autentificarea cu doi factori îți păstrează contul în siguranță.',
    },
    {
      title: 'Mii de sloturi',
      description: 'Și peste 50 de mese live.',
    },
    {
      title: 'Pariuri sportive impresionante',
      description: 'Ele oferă marje scăzute și o gamă largă de piețe live.',
    },
    {
      title: 'Pariuri exotice',
      description: 'Poți paria pe divertisment și politică',
    },
    {
      title: 'Retrageri rapide',
      description: 'Multe retrageri durează aproximativ 10 minute.',
    },
  ],

  con: [
    {
      title: 'Cote la pariuri sportive',
      description:
        'Alte case de pariuri oferă cote mai bune la evenimente majore.',
    },
    {
      title: 'Informații de contact limitate',
      description: 'Restricții, taxe și detalii de plată greu de găsit.',
    },
    {
      title: 'Program VIP pe invitație',
      description:
        'Nu te poți decide să te alături, dar dacă ești selectat înseamnă că ești cu adevărat un VIP!',
    },
    {
      title: 'Fără jocuri exclusive',
      description:
        'Dar cu o gamă atât de mare de jocuri de cazinou, s-ar putea să nu îți infuelnțeze experiența de joc.',
    },
    {
      title: 'Taxă de manipulare posibilă',
      description: 'Aceștia pot percepe o taxă de gestionare pentru depozite.',
    },
  ],
};
