import React from 'react';
import '../css/styles.css';
import '../css/bootstrap.min.css';

import { posts } from '../components/BlogPost/data';

function FullBlogSection() {
  return (
    <div className="blog-section padding-top padding-bottom">
      <div className="container">
        <div className="section-wrapper">
          <div className="row g-4 justify-content-center">
            <div className="col-12">
              <div className="blog-item">
                <div className="blog-inner d-flex flex-wrap align-items-center">
                  <div className="blog-thumb  w-100 w-xl-50">
                    <img
                      src={posts[0].image}
                      width="550"
                      alt="blog-thumb"
                      //   className="w-100"
                    />
                  </div>
                  <div className="blog-content p-4 w-100  w-xl-50">
                    <a href="blog-single.html">
                      <h4>{posts[0].title}</h4>
                    </a>
                    <div className="meta-post">
                      <a href="#">Admin</a>
                      <a href="#">05 January 2022</a>
                    </div>
                    <p>{posts[0].description}</p>
                    <a href={`/${posts[0].URL}`} className="default-button">
                      <span>
                        Citeste mai mult{' '}
                        <i className="icofont-circled-right"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {posts.slice(1, 3).map(post => (
              <div className="col-lg-6 col-12">
                <div className="blog-item">
                  <div className="blog-inner">
                    <div className="blog-thumb">
                      <img
                        src={post.image}
                        alt="blog-thumb"
                        className="w-100"
                      />
                    </div>
                    <div className="blog-content px-3 py-4">
                      <a href="blog-single.html">
                        <h4>{post.title}</h4>
                      </a>
                      <div className="meta-post">
                        <a href="#">Admin</a>
                        <a href="#">05 January 2022</a>
                      </div>
                      <p>{post.description}</p>
                      <a
                        href={`/${post.URL}`}
                        className="default-button reverse-effect"
                      >
                        <span>
                          Citeste mai mult{' '}
                          <i className="icofont-circled-right"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {posts.slice(3).map(post => (
              <div className="col-xl-4 col-md-6 col-12">
                <div className="blog-item">
                  <div className="blog-inner">
                    <div className="blog-thumb">
                      <img
                        src={post.image}
                        alt="blog-thumb"
                        className="w-100"
                      />
                    </div>
                    <div className="blog-content px-3 py-4">
                      <a href="blog-single.html">
                        <h4>{post.title}</h4>
                      </a>
                      <div className="meta-post">
                        <a href="#">Admin</a>
                        <a href="#">05 January 2022</a>
                      </div>
                      <p>{post.description}</p>
                      <a href={`/${post.URL}`} className="default-button">
                        <span>
                          Citeste mai mult{' '}
                          <i className="icofont-circled-right"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FullBlogSection;
