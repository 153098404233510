import React from 'react';
import '../css/styles.css';
import { SimpleGrid } from '@chakra-ui/react';

function About() {
  return (
    <section
      style={{ backgroundColor: '#080e37', color: '#ffffff' }}
      className="collection-section padding-top padding-bottom"
    >
      <div className="container">
        <div className="section-header">
          <h2>bine ai venit la crypto bet casino</h2>
          <p style={{ fontSize: '18px' }}>
            Explorăm, evaluăm și evidențiem cele mai bune cazinouri cu
            criptomonede, oferindu-ti încredere și liniște în lumea pariurilor
            online.
          </p>
        </div>
        <div className="section-wrapper game">
          <div class="row g-4 justify-content-center">
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="game__item item-layer">
                <div className="game__inner text-center">
                  <div className="game__thumb">
                    <img
                      style={{ margin: 'auto' }}
                      src={require('../images/game/01.png')}
                      alt="game-img"
                    />
                  </div>
                  <div className="game__content">
                    <h4>
                      <a href="team-single.html">Educație</a>{' '}
                    </h4>
                    <p style={{ fontSize: '18px' }}>
                      Descoperă lumea fascinantă a cripto-gamingului cu
                      ghidurile și tutorialele noastre detaliate. Îți oferim
                      informațiile esențiale pentru a naviga cu încredere și
                      a-ți maximiza câștigurile. Joacă responsabil!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="game__item item-layer">
                <div className="game__inner text-center">
                  <div className="game__thumb">
                    <img
                      style={{ margin: 'auto' }}
                      src={require('../images/game/02.png')}
                      alt="game-img"
                    />
                  </div>
                  <div className="game__content">
                    <h4>
                      <a href="team-single.html">Recenzii</a>{' '}
                    </h4>
                    <p style={{ fontSize: '18px' }}>
                      Găsește cazinoul cu criptomonede care ți se potrivește cel
                      mai bine cu ajutorul comparațiilor și recenziilor noastre
                      obiective. Evidențiem cele mai bune caracteristici,
                      serviciul pentru clienți, opțiunile de plată și varietatea
                      jocurilor pentru a te ajuta să faci cea mai bună alegere.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="game__item item-layer">
                <div className="game__inner text-center">
                  <div className="game__thumb">
                    <img
                      style={{ margin: 'auto' }}
                      src={require('../images/game/03.png')}
                      alt="game-img"
                    />
                  </div>
                  <div className="game__content">
                    <h4>
                      <a href="team-single.html">Știri</a>{' '}
                    </h4>
                    <p style={{ fontSize: '18px' }}>
                      Rămâi la curent cu cele mai noi știri și tendințe din
                      lumea cazinourilor cu criptomonede. Te ținem informat
                      despre schimbările legislative, tehnologiile emergente și
                      evoluțiile pieței pentru a te ajuta să rămâi un pas
                      înainte.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
