import { theme as proTheme } from '@chakra-ui/pro-theme';
import { extendTheme, theme as baseTheme } from '@chakra-ui/react';

import '@fontsource/montserrat';

export const theme = extendTheme(proTheme, {
  colors: { ...baseTheme.colors, brand: baseTheme.colors.red },
  fonts: {
    heading: `'Oswald', sans-serif`,
    body: `'Barlow', sans-serif`,
  },
});
